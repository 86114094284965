import React, { useEffect } from 'react';
import useStyles from './style';
import NewTextLoading from './components/Loading';
import { createNewDocument } from '../../../../stories/actions/documents';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useNavigate } from 'react-router-dom';

const NewText = () => {
  const classes = useStyles();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const cb = {
      success: (_id: any) => {
        navigate(`/edit-text/${_id}`);
      },
      error: () => {
        navigate('/home');
      }
    };

    dispatch(createNewDocument(cb));
  }, []);

  return <NewTextLoading loading={true} />;
};

export default NewText;
