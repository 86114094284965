import React from 'react';
import { AppBar, Toolbar, useMediaQuery } from '@mui/material';
import SecurityDescriptionNavBar from './component/SecurityDescription';
import PoweredByStripeImg from '../../../../../../../assets/images/powered-by-stripe.png';
import LogoNavBar from './component/Logo';
import useStyles from './styles';
import { ACTIVE_COUPON } from '../../../../../../../utils/coupon';
import { getPlatformVersion } from '../../../../../../../utils/function/getPlatformVersion';
import { useReduxState } from '../../../../../../../hooks/useReduxState';
import { TranslateInterface } from '../../../../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../../../../utils/hooks/useLanguage';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import {
  fastFreemiumActive,
  updateBrandActive
} from '../../../../../../../stories/actions/user';
import { useAppDispatch } from '../../../../../../../hooks/useAppDispatch';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import success = toast.success;

const NavBarPayment = () => {
  const classes = useStyles();

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  const { payment, user, documents } = useReduxState();

  const { translate }: TranslateInterface = useLanguage();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const handleClose = () => {
    const cb = {
      success: () => {
        const standardText: any = documents.all?.find(
          (document: any) => document.standardText
        );

        if (standardText) {
          return navigate(`/edit-text/${standardText._id}`);
        }

        return navigate('/home');
      },
      error: () => {
        // navigate('/home');
      }
    };

    dispatch(fastFreemiumActive(cb));
  };

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar} elevation={0}>
        <Toolbar className={classes.toolbar}>
          <LogoNavBar />

          {ACTIVE_COUPON === 'NO_DISCOUNT' && (
            <div className={classes.space}></div>
          )}

          {ACTIVE_COUPON === 'ANUAL40' &&
            getPlatformVersion(user) === 'standard' && (
              <div className={classes.space}>
                {!isMobile && (
                  <p
                    style={{
                      color: '#000000',
                      textAlign: 'center',
                      marginLeft: '142px'
                    }}
                    dangerouslySetInnerHTML={{
                      __html: translate(
                        'page.plans.discount.ANUAL40.description'
                      )
                    }}
                  />
                )}
              </div>
            )}

          {ACTIVE_COUPON === 'ANUAL40' &&
            getPlatformVersion(user) !== 'standard' && (
              <div className={classes.space}>
                {!isMobile && (
                  <p
                    style={{
                      color: '#000000',
                      textAlign: 'center',
                      marginLeft: '142px'
                    }}
                  ></p>
                )}
              </div>
            )}

          {ACTIVE_COUPON === 'NIVER40' &&
            getPlatformVersion(user) === 'standard' && (
              <div className={classes.space}>
                {!isMobile && (
                  <p
                    style={{
                      color: '#000000',
                      textAlign: 'center',
                      marginLeft: '142px'
                    }}
                    dangerouslySetInnerHTML={{
                      __html: translate(
                        'page.plans.discount.NIVER40.description'
                      )
                    }}
                  />
                )}
              </div>
            )}

          {ACTIVE_COUPON === 'NIVER40' &&
            getPlatformVersion(user) !== 'standard' && (
              <div className={classes.space}>
                {!isMobile && (
                  <p
                    style={{
                      color: '#000000',
                      textAlign: 'center',
                      marginLeft: '142px'
                    }}
                  ></p>
                )}
              </div>
            )}

          {ACTIVE_COUPON === 'NIVER60' &&
            getPlatformVersion(user) === 'standard' && (
              <div className={classes.space}>
                {!isMobile && (
                  <p
                    style={{
                      color: '#000000',
                      textAlign: 'center',
                      marginLeft: '142px'
                    }}
                    dangerouslySetInnerHTML={{
                      __html: translate(
                        'page.plans.discount.NIVER60.description'
                      )
                    }}
                  />
                )}
              </div>
            )}

          {ACTIVE_COUPON === 'NIVER60' &&
            getPlatformVersion(user) !== 'standard' && (
              <div className={classes.space}>
                {!isMobile && (
                  <p
                    style={{
                      color: '#000000',
                      textAlign: 'center',
                      marginLeft: '142px'
                    }}
                  ></p>
                )}
              </div>
            )}

          {ACTIVE_COUPON === 'LINGUAPT50' && (
            <div className={classes.space}>
              {!isMobile && (
                <p
                  style={{
                    color: '#000000',
                    textAlign: 'center',
                    marginLeft: '142px'
                  }}
                >
                  Utilize o cupom{' '}
                  <b
                    style={{
                      color: '#410A85'
                    }}
                  >
                    LINGUAPT50
                  </b>{' '}
                  e ganhe 50% de desconto na assinatura anual. Aproveite! 🎉
                </p>
              )}
            </div>
          )}

          {ACTIVE_COUPON === 'WS50' && (
            <div className={classes.space}>
              {!isMobile && (
                <p
                  style={{
                    color: '#000000',
                    textAlign: 'center',
                    marginLeft: '142px'
                  }}
                >
                  Utilize o cupom{' '}
                  <b
                    style={{
                      color: '#410A85'
                    }}
                  >
                    WS50
                  </b>{' '}
                  e ganhe 50% de desconto na assinatura anual. Aproveite! 🎉
                </p>
              )}
            </div>
          )}

          {ACTIVE_COUPON === 'CLARICE60' &&
            getPlatformVersion(user) === 'standard' && (
              <div className={classes.space}>
                {!isMobile && (
                  <p
                    style={{
                      color: '#000000',
                      textAlign: 'center',
                      marginLeft: '142px'
                    }}
                  >
                    Utilize o cupom{' '}
                    <b
                      style={{
                        color: '#410A85'
                      }}
                    >
                      CLARICE60
                    </b>{' '}
                    e ganhe 60% de desconto na assinatura anual. Aproveite! 🎉
                  </p>
                )}
              </div>
            )}

          {ACTIVE_COUPON === 'CLARICE50' &&
            getPlatformVersion(user) === 'standard' && (
              <div className={classes.space}>
                {!isMobile && (
                  <p
                    style={{
                      color: '#000000',
                      textAlign: 'center',
                      marginLeft: '142px'
                    }}
                  >
                    Utilize o cupom{' '}
                    <b
                      style={{
                        color: '#410A85'
                      }}
                    >
                      CLARICE50
                    </b>{' '}
                    e ganhe 50% de desconto na assinatura anual. Aproveite! 🎉
                  </p>
                )}
              </div>
            )}

          {getPlatformVersion(user) !== 'standard' && (
            <div className={classes.space}>
              {!isMobile && (
                <p
                  style={{
                    color: '#000000',
                    textAlign: 'center',
                    marginLeft: '142px'
                  }}
                ></p>
              )}
            </div>
          )}

          <SecurityDescriptionNavBar />

          <img src={PoweredByStripeImg} alt="" height={'24px'} />

          {/*<Tooltip title={translate('page.plans.notDecideNow')} arrow>*/}
          {/*  <IconButton*/}
          {/*    onClick={handleClose}*/}
          {/*    size={'small'}*/}
          {/*    // className={classes.closeButton}*/}
          {/*    sx={{*/}
          {/*      // position: 'absolute',*/}
          {/*      // top: 8,*/}
          {/*      left: 20,*/}
          {/*      backgroundColor: '#f0f0f0',*/}
          {/*      '&:hover': {*/}
          {/*        backgroundColor: '#e0e0e0'*/}
          {/*      }*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    <CloseIcon />*/}
          {/*  </IconButton>*/}
          {/*</Tooltip>*/}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default NavBarPayment;
