import React, { useContext, useEffect, useState } from 'react';
import useStyles from './style';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { ThemeContext } from '../../../../utils/context/ThemeContext';
import FullLogo from '../../../../assets/images/jsx-icons/full-logo';
import PlanContainer from './components/planContainer';
import { getPlans } from '../../../../stories/actions/payment';
import { loadStripe } from '@stripe/stripe-js';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useReduxState } from '../../../../hooks/useReduxState';
import withAuth from '../../../../utils/axios/withAuth';
import { ACTIVE_COUPON } from '../../../../utils/coupon';
import { useNavigate } from 'react-router-dom';
import NavBarPayment from '../payment/old/components/NavBar';
import Teste from '../../../../assets/images/payment/person.svg';
import { isBlackWeek } from '../../../../utils/function/isBlackWeek';
import { getPlatformVersion } from '../../../../utils/function/getPlatformVersion';
import { TranslateInterface } from '../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../utils/hooks/useLanguage';
import analytics from '../../../../utils/function/analytics';
import { fastFreemiumActive } from '../../../../stories/actions/user';

let stripe;

const keys = {
  live: 'pk_live_51HiMQkCh5qCS77obLnokWCFM1PbNO7GN1lxLyb92SEGel8C56eahoSddx8Le0gh5EZVvpSbBxyRiv0iizx7cmtiH00X15HmHg9',
  test: 'pk_test_51HiMQkCh5qCS77obmsIUxOvGLPittmEG4gsizSct7rqQYskaFIZGl28XoKtRY4ZhBXoRmqzvjREFmfB7XFcBpkQN00p8R5CVrj'
};

const formatPrice = (amount: number) =>
  String((amount / 100).toFixed(2)).replace('.', ',');

function getClientReferenceId() {
  return (
    // @ts-ignore
    (window.Rewardful && window.Rewardful.referral) ||
    'checkout_' + new Date().getTime()
  );
}

const Plans = () => {
  const classes = useStyles();

  const themeMode = useContext(ThemeContext);

  const [planType, setPlanType] = useState('year');

  const [plans, setPlans] = useState<any>([]);

  const [word, setWord] = useState<any>('unlimited');

  const dispatch = useAppDispatch();

  const { payment, user, documents } = useReduxState();

  const navigate = useNavigate();

  const { translate, settings }: TranslateInterface = useLanguage();

  const onTogglePlanType = (type: string) => {
    setPlanType(type);
  };

  const handleFastFreemiumActive = () => {
    const cb = {
      success: () => {
        const standardText: any = documents.all?.find(
          (document: any) => document.standardText
        );

        if (standardText) {
          return navigate(`/edit-text/${standardText._id}`);
        }

        return navigate('/home');
      },
      error: () => {
        // navigate('/home');
      }
    };

    dispatch(fastFreemiumActive(cb));
  };

  useEffect(() => {
    if (user.isSubscriber) {
      return navigate('/account/credits-billings');
    }
  }, [user.isSubscriber]);

  useEffect(() => {
    loadStripe(
      ['app-stg.clarice.ai', 'tests.clarice.ai', 'local-host'].includes(
        window.location.hostname
      )
        ? keys.test
        : keys.live,
      {
        locale: settings.language || 'pt-BR'
      }
    ).then(s => {
      stripe = s;
    });
    // .catch(e => console.log(e));
  }, []);

  const isBlackFriday = isBlackWeek();
  // && getPlatformVersion(user) === 'standard';

  useEffect(() => {
    if (payment.plans) {
      const formatted: any = {
        creative: {
          monthly: {
            custom: {
              title: translate('page.plans.creative.title'),
              subtitle: translate('page.plans.creative.subtitle'),
              description: translate('page.plans.creative.description'),
              benefits: [
                {
                  key: 1,
                  text: translate('page.plans.creative.benefits.one')
                },
                {
                  key: 2,
                  text: translate('page.plans.creative.benefits.two')
                },
                {
                  key: 3,
                  text: translate('page.plans.creative.benefits.three')
                },
                {
                  key: 4,
                  text: translate('page.plans.creative.benefits.four')
                },
                {
                  key: 5,
                  text: translate('page.plans.creative.benefits.five')
                },
                {
                  key: 6,
                  text: translate('page.plans.creative.benefits.six')
                },
                {
                  key: 7,
                  text: translate('page.plans.creative.benefits.seven')
                },
                {
                  key: 8,
                  text: translate('page.plans.creative.benefits.eight')
                }
              ],
              company: false,
              bestPlan: false
            }
          },
          yearly: {
            custom: {
              title: translate('page.plans.creative.title'),
              subtitle: translate('page.plans.creative.subtitle'),
              description: translate('page.plans.creative.description'),
              benefits: [
                {
                  key: 1,
                  text: translate('page.plans.creative.benefits.one')
                },
                {
                  key: 2,
                  text: translate('page.plans.creative.benefits.two')
                },
                {
                  key: 3,
                  text: translate('page.plans.creative.benefits.three')
                },
                {
                  key: 4,
                  text: translate('page.plans.creative.benefits.four')
                },
                {
                  key: 5,
                  text: translate('page.plans.creative.benefits.five')
                },
                {
                  key: 6,
                  text: translate('page.plans.creative.benefits.six')
                },
                {
                  key: 7,
                  text: translate('page.plans.creative.benefits.seven')
                },
                {
                  key: 8,
                  text: translate('page.plans.creative.benefits.eight')
                }
              ],
              company: false,
              bestPlan: false
            }
          }
        },
        professional: {
          monthly: {
            custom: {
              title: translate('page.plans.professional.title'),
              subtitle: translate('page.plans.professional.subtitle'),
              description: translate('page.plans.professional.description'),
              benefits: [
                {
                  key: 1,
                  text: translate('page.plans.professional.benefits.one')
                },
                {
                  key: 2,
                  text: translate('page.plans.professional.benefits.two')
                },
                {
                  key: 3,
                  text: translate('page.plans.professional.benefits.three')
                },
                {
                  key: 4,
                  text: translate('page.plans.professional.benefits.four')
                },
                {
                  key: 5,
                  text: translate('page.plans.professional.benefits.five')
                },
                {
                  key: 6,
                  text: translate('page.plans.professional.benefits.six')
                },
                {
                  key: 7,
                  text: translate('page.plans.professional.benefits.seven')
                },
                {
                  key: 8,
                  text: translate('page.plans.professional.benefits.eight')
                }
              ],
              company: false,
              bestPlan: true
            }
          },
          yearly: {
            custom: {
              title: translate('page.plans.professional.title'),
              subtitle: translate('page.plans.professional.subtitle'),
              description: translate('page.plans.professional.description'),
              benefits: [
                {
                  key: 1,
                  text: translate('page.plans.professional.benefits.one')
                },
                {
                  key: 2,
                  text: translate('page.plans.professional.benefits.two')
                },
                {
                  key: 3,
                  text: translate('page.plans.professional.benefits.three')
                },
                {
                  key: 4,
                  text: translate('page.plans.professional.benefits.four')
                },
                {
                  key: 5,
                  text: translate('page.plans.professional.benefits.five')
                },
                {
                  key: 6,
                  text: translate('page.plans.professional.benefits.six')
                },
                {
                  key: 7,
                  text: translate('page.plans.professional.benefits.seven')
                },
                {
                  key: 8,
                  text: translate('page.plans.professional.benefits.eight')
                }
              ],
              company: false,
              bestPlan: true
            }
          }
        },
        company: {
          monthly: {
            custom: {
              title: translate('page.plans.company.title'),
              subtitle: translate('page.plans.company.subtitle'),
              description: translate('page.plans.company.description'),
              benefits: [
                {
                  key: 1,
                  text: translate('page.plans.company.benefits.one')
                },
                {
                  key: 2,
                  text: translate('page.plans.company.benefits.two')
                },
                {
                  key: 3,
                  text: translate('page.plans.company.benefits.three')
                },
                {
                  key: 4,
                  text: translate('page.plans.company.benefits.four')
                },
                {
                  key: 5,
                  text: translate('page.plans.company.benefits.five')
                },
                {
                  key: 6,
                  text: translate('page.plans.company.benefits.six')
                },
                {
                  key: 7,
                  text: translate('page.plans.company.benefits.seven')
                },
                {
                  key: 8,
                  text: translate('page.plans.company.benefits.eight')
                }
              ],
              company: true,
              bestPlan: false
            }
          },
          yearly: {
            custom: {
              title: translate('page.plans.company.title'),
              subtitle: translate('page.plans.company.subtitle'),
              description: translate('page.plans.company.description'),
              benefits: [
                {
                  key: 1,
                  text: translate('page.plans.company.benefits.one')
                },
                {
                  key: 2,
                  text: translate('page.plans.company.benefits.two')
                },
                {
                  key: 3,
                  text: translate('page.plans.company.benefits.three')
                },
                {
                  key: 4,
                  text: translate('page.plans.company.benefits.four')
                },
                {
                  key: 5,
                  text: translate('page.plans.company.benefits.five')
                },
                {
                  key: 6,
                  text: translate('page.plans.company.benefits.six')
                },
                {
                  key: 7,
                  text: translate('page.plans.company.benefits.seven')
                },
                {
                  key: 8,
                  text: translate('page.plans.company.benefits.eight')
                }
              ],
              company: true,
              bestPlan: false
            }
          }
        },
        lite: {
          monthly: {
            custom: {
              title: translate('page.plans.lite.monthly.title'),
              company: false,
              bestPlan: false
            }
          },
          semiAnnual: {
            custom: {
              title: translate('page.plans.lite.semiAnnual.title'),
              company: false,
              bestPlan: false
            }
          },
          yearly: {
            custom: {
              title: translate('page.plans.lite.yearly.title'),
              company: false,
              bestPlan: true
            }
          }
        }
      };

      const plans = payment.plans.filter(plan => {
        if (settings.language.includes('en')) {
          return plan.currency === 'usd';
        }

        if (settings.language.includes('es')) {
          return plan.currency === 'eur';
        }

        return plan.currency === 'brl';
      });

      console.log(plans);

      plans.map(plan => {
        if (plan.nickname === 'Subscription Lite Monthly') {
          formatted.lite.monthly = {
            ...formatted.lite.monthly,
            ...plan,
            amount:
              ACTIVE_COUPON === 'NIVER60' ? plan.amount * 0.4 : plan.amount
          };
        }

        if (plan.nickname === 'Subscription Lite Semi-Annual') {
          formatted.lite.semiAnnual = {
            ...formatted.lite.semiAnnual,
            ...plan,
            amount: ACTIVE_COUPON === 'NIVER60' ? 2490 * 0.4 : 2490,
            yearlyMessage: (
              <div style={{ fontSize: '13.5px' }}>
                {ACTIVE_COUPON === 'NIVER60' ? (
                  <>
                    Uma cobrança <b>semestral </b> de{' '}
                    <span
                      style={{
                        textDecoration: 'line-through',
                        color: 'rgb(255 0 74 / 86%)'
                      }}
                    >
                      <b>R$179,40</b>
                    </span>{' '}
                    <b>⭢ R$59,76</b>
                  </>
                ) : (
                  <>
                    Uma cobrança <b>semestral </b> de{' '}
                    <span
                      style={{
                        textDecoration: 'line-through',
                        color: 'rgb(255 0 74 / 86%)'
                      }}
                    >
                      <b>R$179,40</b>
                    </span>{' '}
                    <b>⭢ R$149,40</b>
                  </>
                )}
              </div>
            )
          };
        }

        if (plan.nickname === 'Subscription Lite Yearly') {
          formatted.lite.yearly = {
            ...formatted.lite.yearly,
            ...plan,
            // amount: 2990,
            amount: ACTIVE_COUPON === 'NIVER60' ? 1990 * 0.4 : 1990,
            yearlyMessage: (
              <div style={{ fontSize: '13.5px' }}>
                {ACTIVE_COUPON === 'NIVER60' ? (
                  <>
                    Uma cobrança <b>anual</b> de{' '}
                    <span
                      style={{
                        textDecoration: 'line-through',
                        color: 'rgb(255 0 74 / 86%)'
                      }}
                    >
                      <b>R$358,80</b>
                    </span>{' '}
                    <b>⭢ R$95,52</b>
                  </>
                ) : (
                  <>
                    Uma cobrança <b>anual</b> de{' '}
                    <span
                      style={{
                        textDecoration: 'line-through',
                        color: 'rgb(255 0 74 / 86%)'
                      }}
                    >
                      <b>R$358,80</b>
                    </span>{' '}
                    <b>⭢ R$238,80</b>
                  </>
                )}
              </div>
            )
          };
        }

        if (plan.nickname === 'Assinatura Mensal | Criativo') {
          formatted.creative.monthly = {
            ...formatted.creative.monthly,
            ...plan,
            amount:
              ACTIVE_COUPON === 'NIVER60' ? plan.amount * 0.4 : plan.amount
          };
        }

        if (plan.nickname === 'Assinatura Anual | Criativo') {
          formatted.creative.yearly = {
            ...formatted.creative.yearly,
            ...plan,
            amount: ACTIVE_COUPON === 'NIVER60' ? 3800 * 0.4 : 3800,
            yearlyMessage: (
              <div style={{ fontSize: '13.5px' }}>
                {ACTIVE_COUPON === 'ANUAL40' && (
                  <>
                    Uma cobrança <b>anual</b> de{' '}
                    <span
                      style={{
                        textDecoration: 'line-through',
                        color: 'rgb(255 0 74 / 86%)'
                      }}
                    >
                      <b>R$449,00</b>
                    </span>{' '}
                    <b>⭢ R$269,40</b>
                  </>
                )}

                {ACTIVE_COUPON === 'NIVER40' && (
                  <>
                    Uma cobrança <b>anual</b> de{' '}
                    <span
                      style={{
                        textDecoration: 'line-through',
                        color: 'rgb(255 0 74 / 86%)'
                      }}
                    >
                      <b>R$449,00</b>
                    </span>{' '}
                    <b>⭢ R$269,40</b>
                  </>
                )}

                {ACTIVE_COUPON === 'NIVER60' && (
                  <>
                    Uma cobrança <b>anual</b> de{' '}
                    <span
                      style={{
                        textDecoration: 'line-through',
                        color: 'rgb(255 0 74 / 86%)'
                      }}
                    >
                      <b>R$449,00</b>
                    </span>{' '}
                    <b>⭢ R$179,60</b>
                  </>
                )}

                {ACTIVE_COUPON === 'CLARICE60' && (
                  <>
                    Uma cobrança <b>anual</b> de{' '}
                    <span
                      style={{
                        textDecoration: 'line-through',
                        color: 'rgb(255 0 74 / 86%)'
                      }}
                    >
                      <b>R$449,00</b>
                    </span>{' '}
                    <b>⭢ R$179,60</b>
                  </>
                )}

                {ACTIVE_COUPON === 'CLARICE50' && (
                  <>
                    Uma cobrança <b>anual</b> de{' '}
                    <span
                      style={{
                        textDecoration: 'line-through',
                        color: 'rgb(255 0 74 / 86%)'
                      }}
                    >
                      <b>R$449,00</b>
                    </span>{' '}
                    <b>⭢ R$224,50</b>
                  </>
                )}

                {ACTIVE_COUPON === 'WS50' && (
                  <>
                    Uma cobrança <b>anual</b> de{' '}
                    <span
                      style={{
                        textDecoration: 'line-through',
                        color: 'rgb(255 0 74 / 86%)'
                      }}
                    >
                      <b>R$449,00</b>
                    </span>{' '}
                    <b>⭢ R$224,50</b>
                  </>
                )}

                {ACTIVE_COUPON === 'NO_DISCOUNT' && (
                  <>
                    Uma cobrança <b>anual</b> de <b>R$449,00</b>
                  </>
                )}
              </div>
            )
          };
        }

        if (plan.nickname === 'Assinatura Mensal | Profissional') {
          formatted.professional.monthly = {
            ...formatted.professional.monthly,
            plans: {
              ...formatted.professional.monthly.plans,
              unlimited: {
                ...plan,
                amount:
                  ACTIVE_COUPON === 'NIVER60' ? plan.amount * 0.4 : plan.amount
              }
            }
          };
        }

        if (plan.nickname === 'Assinatura Anual | Profissional') {
          formatted.professional.yearly = {
            ...formatted.professional.yearly,
            plans: {
              ...formatted.professional.yearly.plans,
              unlimited: {
                ...plan,
                amount: ACTIVE_COUPON === 'NIVER60' ? 7500 * 0.4 : 7500,
                yearlyMessage: (
                  <div style={{ fontSize: '13.5px' }}>
                    {ACTIVE_COUPON === 'ANUAL40' && (
                      <>
                        Uma cobrança <b>anual</b> de{' '}
                        <span
                          style={{
                            textDecoration: 'line-through',
                            color: 'rgb(255 0 74 / 86%)'
                          }}
                        >
                          <b>R$899,00</b>
                        </span>{' '}
                        <b>⭢ R$539,40</b>
                      </>
                    )}

                    {ACTIVE_COUPON === 'NIVER40' && (
                      <>
                        Uma cobrança <b>anual</b> de{' '}
                        <span
                          style={{
                            textDecoration: 'line-through',
                            color: 'rgb(255 0 74 / 86%)'
                          }}
                        >
                          <b>R$899,00</b>
                        </span>{' '}
                        <b>⭢ R$539,40</b>
                      </>
                    )}

                    {ACTIVE_COUPON === 'NIVER60' && (
                      <>
                        Uma cobrança <b>anual</b> de{' '}
                        <span
                          style={{
                            textDecoration: 'line-through',
                            color: 'rgb(255 0 74 / 86%)'
                          }}
                        >
                          <b>R$899,00</b>
                        </span>{' '}
                        <b>⭢ R$359,60</b>
                      </>
                    )}

                    {ACTIVE_COUPON === 'WS50' && (
                      <>
                        Uma cobrança <b>anual</b> de{' '}
                        <span
                          style={{
                            textDecoration: 'line-through',
                            color: 'rgb(255 0 74 / 86%)'
                          }}
                        >
                          <b>R$899,00</b>
                        </span>{' '}
                        <b>⭢ R$449,50</b>
                      </>
                    )}

                    {ACTIVE_COUPON === 'NO_DISCOUNT' && (
                      <>
                        Uma cobrança <b>anual</b> de <b>R$899,00</b>
                      </>
                    )}

                    {ACTIVE_COUPON === 'CLARICE60' && (
                      <>
                        Uma cobrança <b>anual</b> de{' '}
                        <span
                          style={{
                            textDecoration: 'line-through',
                            color: 'rgb(255 0 74 / 86%)'
                          }}
                        >
                          <b>R$899,00</b>
                        </span>{' '}
                        <b>⭢ R$359,60</b>
                      </>
                    )}

                    {ACTIVE_COUPON === 'CLARICE50' && (
                      <>
                        Uma cobrança <b>anual</b> de{' '}
                        <span
                          style={{
                            textDecoration: 'line-through',
                            color: 'rgb(255 0 74 / 86%)'
                          }}
                        >
                          <b>R$899,00</b>
                        </span>{' '}
                        <b>⭢ R$449,50</b>
                      </>
                    )}
                  </div>
                )
              }
            }
          };
        }

        // USD

        // Subscription Lite Monthly
        if (plan.id === 'price_1Q9nFbCh5qCS77obhOSJE1nV') {
          formatted.lite.monthly = {
            ...formatted.lite.monthly,
            ...plan
          };
        }

        // Subscription Lite Semi-Annual
        if (plan.id === 'price_1Q9nGxCh5qCS77obhk1anBuK') {
          formatted.lite.semiAnnual = {
            ...formatted.lite.semiAnnual,
            ...plan,
            amount: 597,
            yearlyMessage: (
              <div style={{ fontSize: '13.5px' }}>
                <>
                  <b>Semi Annual billing</b> of{' '}
                  <span
                    style={{
                      textDecoration: 'line-through',
                      color: 'rgb(255 0 74 / 86%)'
                    }}
                  >
                    <b>$49,90</b>
                  </span>{' '}
                  <b>⭢ $29,94</b>
                </>
              </div>
            )
          };
        }

        // Subscription Lite Yearly
        if (plan.id === 'price_1Q9nITCh5qCS77obQ2oBl4WR') {
          formatted.lite.yearly = {
            ...formatted.lite.yearly,
            ...plan,
            // amount: 2990,
            amount: 398,
            yearlyMessage: (
              <div style={{ fontSize: '13.5px' }}>
                <>
                  <b>Annual billing</b> of{' '}
                  <span
                    style={{
                      textDecoration: 'line-through',
                      color: 'rgb(255 0 74 / 86%)'
                    }}
                  >
                    <b>$79,90</b>
                  </span>{' '}
                  <b>⭢ $47,94</b>
                </>
              </div>
            )
          };
        }

        // CREATIVE MONTHLY
        if (plan.id === 'price_1Q8pzACh5qCS77obw36LNj3u') {
          formatted.creative.monthly = {
            ...formatted.creative.monthly,
            ...plan
          };
        }

        // CREATIVE YEARLY
        if (plan.id === 'price_1Q9n8ACh5qCS77obW11h7F2X') {
          formatted.creative.yearly = {
            ...formatted.creative.yearly,
            ...plan,
            amount: 395,
            yearlyMessage: (
              <div style={{ fontSize: '13.5px' }}>
                {ACTIVE_COUPON === 'ANUAL40' && (
                  <>
                    <b>Annual billing</b> of{' '}
                    <span
                      style={{
                        textDecoration: 'line-through',
                        color: 'rgb(255 0 74 / 86%)'
                      }}
                    >
                      <b>$78,80</b>
                    </span>{' '}
                    <b>⭢ $47,40</b>
                  </>
                )}

                {ACTIVE_COUPON === 'NIVER40' && (
                  <>
                    <b>Annual billing</b> of{' '}
                    <span
                      style={{
                        textDecoration: 'line-through',
                        color: 'rgb(255 0 74 / 86%)'
                      }}
                    >
                      <b>$78,80</b>
                    </span>{' '}
                    <b>⭢ $47,40</b>
                  </>
                )}

                {ACTIVE_COUPON === 'NIVER60' && (
                  <>
                    <b>Annual billing</b> of{' '}
                    <span
                      style={{
                        textDecoration: 'line-through',
                        color: 'rgb(255 0 74 / 86%)'
                      }}
                    >
                      <b>$78,80</b>
                    </span>{' '}
                    <b>⭢ $31,52</b>
                  </>
                )}

                {ACTIVE_COUPON === 'NO_DISCOUNT' && (
                  <>
                    <b>Annual billing</b> of <b>R$47,40</b>
                  </>
                )}
              </div>
            )
          };
        }

        // PROFESSIONAL MONTHLY
        if (plan.id === 'price_1Q9nAKCh5qCS77ob9GYBW8ii') {
          formatted.professional.monthly = {
            ...formatted.professional.monthly,
            plans: {
              ...formatted.professional.monthly.plans,
              unlimited: { ...plan }
            }
          };
        }

        // PROFESSIONAL YEARLY
        if (plan.id === 'price_1Q9nDNCh5qCS77obukTvLs47') {
          formatted.professional.yearly = {
            ...formatted.professional.yearly,
            plans: {
              ...formatted.professional.yearly.plans,
              unlimited: {
                ...plan,
                amount: 795,
                yearlyMessage: (
                  <div style={{ fontSize: '13.5px' }}>
                    {ACTIVE_COUPON === 'ANUAL40' && (
                      <>
                        <b>Annual billing</b> of{' '}
                        <span
                          style={{
                            textDecoration: 'line-through',
                            color: 'rgb(255 0 74 / 86%)'
                          }}
                        >
                          <b>$161,40</b>
                        </span>{' '}
                        <b>⭢ $95,40</b>
                      </>
                    )}

                    {ACTIVE_COUPON === 'NIVER40' && (
                      <>
                        <b>Annual billing</b> of{' '}
                        <span
                          style={{
                            textDecoration: 'line-through',
                            color: 'rgb(255 0 74 / 86%)'
                          }}
                        >
                          <b>$161,40</b>
                        </span>{' '}
                        <b>⭢ $95,40</b>
                      </>
                    )}

                    {ACTIVE_COUPON === 'NIVER60' && (
                      <>
                        <b>Annual billing</b> of{' '}
                        <span
                          style={{
                            textDecoration: 'line-through',
                            color: 'rgb(255 0 74 / 86%)'
                          }}
                        >
                          <b>$161,40</b>
                        </span>{' '}
                        <b>⭢ $64,56</b>
                      </>
                    )}

                    {ACTIVE_COUPON === 'NO_DISCOUNT' && (
                      <>
                        <b>Annual billing</b> of <b>$161,40</b>
                      </>
                    )}
                  </div>
                )
              }
            }
          };
        }

        // EUR

        // Subscription Lite Monthly
        if (plan.id === 'price_1Q9nFnCh5qCS77obsXTftVBp') {
          formatted.lite.monthly = {
            ...formatted.lite.monthly,
            ...plan
          };
        }

        // Subscription Lite Semi-Annual
        if (plan.id === 'price_1Q9nHJCh5qCS77obFDtwy3hC') {
          formatted.lite.semiAnnual = {
            ...formatted.lite.semiAnnual,
            ...plan,
            amount: 597,
            yearlyMessage: (
              <div style={{ fontSize: '13.5px' }}>
                <>
                  <b>Semi Annual billing</b> of{' '}
                  <span
                    style={{
                      textDecoration: 'line-through',
                      color: 'rgb(255 0 74 / 86%)'
                    }}
                  >
                    <b>€49,90</b>
                  </span>{' '}
                  <b>⭢ €29,94</b>
                </>
              </div>
            )
          };
        }

        // Subscription Lite Yearly
        if (plan.id === 'price_1Q9nIkCh5qCS77obr81qVmFe') {
          formatted.lite.yearly = {
            ...formatted.lite.yearly,
            ...plan,
            // amount: 2990,
            amount: 398,
            yearlyMessage: (
              <div style={{ fontSize: '13.5px' }}>
                <>
                  <b>Annual billing</b> of{' '}
                  <span
                    style={{
                      textDecoration: 'line-through',
                      color: 'rgb(255 0 74 / 86%)'
                    }}
                  >
                    <b>€79,90</b>
                  </span>{' '}
                  <b>⭢ €47,94</b>
                </>
              </div>
            )
          };
        }

        // CREATIVE MONTHLY
        if (plan.id === 'price_1Q8pzOCh5qCS77objPmkqz0N') {
          formatted.creative.monthly = {
            ...formatted.creative.monthly,
            ...plan
          };
        }

        // CREATIVE YEARLY
        if (plan.id === 'price_1Q9n8YCh5qCS77obfwTAHfgQ') {
          formatted.creative.yearly = {
            ...formatted.creative.yearly,
            ...plan,
            amount: 395,
            yearlyMessage: (
              <div style={{ fontSize: '13.5px' }}>
                {ACTIVE_COUPON === 'ANUAL40' && (
                  <>
                    <b>Annual billing</b> of{' '}
                    <span
                      style={{
                        textDecoration: 'line-through',
                        color: 'rgb(255 0 74 / 86%)'
                      }}
                    >
                      <b>€78,80</b>
                    </span>{' '}
                    <b>⭢ €47,40</b>
                  </>
                )}

                {ACTIVE_COUPON === 'NIVER40' && (
                  <>
                    <b>Annual billing</b> of{' '}
                    <span
                      style={{
                        textDecoration: 'line-through',
                        color: 'rgb(255 0 74 / 86%)'
                      }}
                    >
                      <b>€78,80</b>
                    </span>{' '}
                    <b>⭢ €47,40</b>
                  </>
                )}

                {ACTIVE_COUPON === 'NIVER60' && (
                  <>
                    <b>Annual billing</b> of{' '}
                    <span
                      style={{
                        textDecoration: 'line-through',
                        color: 'rgb(255 0 74 / 86%)'
                      }}
                    >
                      <b>€78,80</b>
                    </span>{' '}
                    <b>⭢ €31,52</b>
                  </>
                )}

                {ACTIVE_COUPON === 'NO_DISCOUNT' && (
                  <>
                    <b>Annual billing</b> of <b>R€47,40</b>
                  </>
                )}
              </div>
            )
          };
        }

        // PROFESSIONAL MONTHLY
        if (plan.id === 'price_1Q9nB0Ch5qCS77obRI9iDDir') {
          formatted.professional.monthly = {
            ...formatted.professional.monthly,
            plans: {
              ...formatted.professional.monthly.plans,
              unlimited: { ...plan }
            }
          };
        }

        // PROFESSIONAL YEARLY
        if (plan.id === 'price_1Q9nDeCh5qCS77obxCKXA5s1') {
          formatted.professional.yearly = {
            ...formatted.professional.yearly,
            plans: {
              ...formatted.professional.yearly.plans,
              unlimited: {
                ...plan,
                amount: 795,
                yearlyMessage: (
                  <div style={{ fontSize: '13.5px' }}>
                    {ACTIVE_COUPON === 'ANUAL40' && (
                      <>
                        <b>Annual billing</b> of{' '}
                        <span
                          style={{
                            textDecoration: 'line-through',
                            color: 'rgb(255 0 74 / 86%)'
                          }}
                        >
                          <b>€161,40</b>
                        </span>{' '}
                        <b>⭢ €95,40</b>
                      </>
                    )}

                    {ACTIVE_COUPON === 'NIVER40' && (
                      <>
                        <b>Annual billing</b> of{' '}
                        <span
                          style={{
                            textDecoration: 'line-through',
                            color: 'rgb(255 0 74 / 86%)'
                          }}
                        >
                          <b>€161,40</b>
                        </span>{' '}
                        <b>⭢ €95,40</b>
                      </>
                    )}

                    {ACTIVE_COUPON === 'NIVER60' && (
                      <>
                        <b>Annual billing</b> of{' '}
                        <span
                          style={{
                            textDecoration: 'line-through',
                            color: 'rgb(255 0 74 / 86%)'
                          }}
                        >
                          <b>€161,40</b>
                        </span>{' '}
                        <b>⭢ €64,56</b>
                      </>
                    )}

                    {ACTIVE_COUPON === 'NO_DISCOUNT' && (
                      <>
                        <b>Annual billing</b> of <b>€161,40</b>
                      </>
                    )}
                  </div>
                )
              }
            }
          };
        }

        return plan;
      });

      setPlans({ ...formatted });
    }
  }, [payment.plans, planType, settings.language]);

  const onRedirectToCheckout = async (priceId: string, type) => {
    const res = await withAuth().post(
      '/payments/create-checkout-session',
      ACTIVE_COUPON === 'NO_DISCOUNT' || type !== 'yearly'
        ? {
            priceId,
            client_reference_id: getClientReferenceId()
          }
        : {
            priceId,
            code: isBlackWeek() ? 'CLARICE50' : ACTIVE_COUPON,
            client_reference_id: getClientReferenceId()
          }
    );

    await stripe.redirectToCheckout(res.data);
  };

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  const setAnalytics = (plan: string, type: string) => {
    let period = type === 'month' ? 'monthly' : 'yearly';

    if (type === 'monthly') {
      period = 'monthly';
    }

    if (type === 'yearly') {
      period = 'yearly';
    }

    if (type === 'semiannual') {
      period = 'semiannual';
    }

    let tag = `select-${plan}-` + period;

    if (settings.language) {
      tag = tag + '-' + settings.language;
    }

    analytics.logEvent('user', tag);
  };

  return (
    <Box
      className={classes.root}
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
      }}
    >
      <NavBarPayment />
      <Box
        className={classes.planHeader}
        sx={{
          marginTop: isMobile ? '150px!important' : '80px!important',
          width: '100%',
          maxWidth: '1500px',
          display: 'flex',
          margin: '2rem 0'
        }}
      >
        <Box
          display={'flex'}
          flexDirection={'row'}
          alignItems="center"
          width={'100%'}
        >
          {!isMobile && (
            <Box className={classes.iconContainer}>
              <img src={Teste} alt="Person" />
            </Box>
          )}
          <Box>
            <Typography
              className={classes.title}
              style={isMobile ? { marginBottom: '15px' } : {}}
            >
              <strong>
                {isBlackFriday && !user.isSubscriber
                  ? 'Black Friday na Clarice.ai! 🔥'
                  : translate('page.plans.title')}
              </strong>
            </Typography>
            <Typography
              className={classes.subtitle}
              style={isMobile ? { marginBottom: '20px' } : {}}
            >
              {isBlackFriday && !user.isSubscriber ? (
                <>
                  Use o cupom <b>CLARICE50</b> para um desconto de <b>50%</b> na
                  assinatura anual!
                </>
              ) : (
                translate('page.plans.description')
              )}
            </Typography>

            {/*<Typography*/}
            {/*  className={classes.notDecideNow}*/}
            {/*  style={isMobile ? { marginBottom: '10px' } : {}}*/}
            {/*  onClick={handleFastFreemiumActive}*/}
            {/*>*/}
            {/*  {translate('page.plans.notDecideNow')}*/}
            {/*</Typography>*/}
          </Box>
        </Box>

        {getPlatformVersion(user) === 'standard' && (
          <Box className={classes.planSelect}>
            <Box
              sx={{
                display: 'flex',
                border: '1px solid rgba(0, 0, 0, 0.1)',
                borderRadius: '1000px',
                width: '227px',
                height: '40.25px',
                // '-webkit-box-align': 'center',
                alignItems: 'center!important',
                justifyContent: 'center',
                padding: '2px'
              }}
            >
              <Typography
                sx={{
                  background: planType === 'month' ? '#7a26f8' : 'transparent',
                  borderRadius: '1000px',
                  cursor: 'pointer',
                  flex: '1 1 0%',
                  height: '100%',
                  display: 'flex',
                  '-webkit-box-pack': 'center',
                  justifyContent: 'center',
                  '-webkit-box-align': 'center',
                  alignItems: 'center',
                  color: planType === 'month' ? '#fff' : 'rgb(26, 32, 44)',
                  fontWeight: '700',
                  fontSize: '0.875em'
                }}
                onClick={() => onTogglePlanType('month')}
              >
                {translate('page.plans.toggle.monthly')}
              </Typography>
              <Typography
                sx={{
                  background: planType === 'year' ? '#7a26f8' : 'transparent',
                  borderRadius: '1000px',
                  cursor: 'pointer',
                  flex: '1 1 0%',
                  height: '100%',
                  display: 'flex',
                  '-webkit-box-pack': 'center',
                  justifyContent: 'center',
                  '-webkit-box-align': 'center',
                  alignItems: 'center',
                  color: planType === 'year' ? '#fff' : 'rgb(26, 32, 44)',
                  fontWeight: '700',
                  fontSize: '0.875em'
                }}
                onClick={() => onTogglePlanType('year')}
              >
                {translate('page.plans.toggle.yearly')}
              </Typography>
            </Box>
            <Typography
              sx={{
                textAlign: 'right',
                fontSize: '0.775em',
                marginTop: '11px'
              }}
              dangerouslySetInnerHTML={{
                __html: translate('page.plans.saveMessage')
              }}
            />
          </Box>
        )}
      </Box>
      <Box
        className={classes.planContainer}
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          gap: '2rem',
          maxWidth: '1300px'
        }}
      >
        {payment.plans.length === 0 && (
          <p style={{ textAlign: 'center' }}>
            {translate('page.plans.loading')}
          </p>
        )}

        {getPlatformVersion(user) === 'standard' && (
          <>
            {payment.plans &&
              payment.plans.length > 0 &&
              plans &&
              plans.creative &&
              plans.creative?.monthly && (
                <PlanContainer
                  nickname={
                    plans.creative[planType === 'month' ? 'monthly' : 'yearly']
                      .nickname
                  }
                  key={
                    plans.creative[planType === 'month' ? 'monthly' : 'yearly']
                      .id
                  }
                  title={
                    plans.creative[planType === 'month' ? 'monthly' : 'yearly']
                      .custom.title
                  }
                  subtitle={
                    plans.creative[planType === 'month' ? 'monthly' : 'yearly']
                      .custom.subtitle
                  }
                  price={
                    plans.creative[planType === 'month' ? 'monthly' : 'yearly']
                      .amount
                  }
                  yearlyMessage={
                    plans.creative[planType === 'month' ? 'monthly' : 'yearly']
                      .yearlyMessage
                      ? plans.creative[
                          planType === 'month' ? 'monthly' : 'yearly'
                        ].yearlyMessage
                      : null
                  }
                  type={planType}
                  benefits={
                    plans.creative[planType === 'month' ? 'monthly' : 'yearly']
                      .custom.benefits
                  }
                  bestPlan={
                    plans.creative[planType === 'month' ? 'monthly' : 'yearly']
                      .custom.bestPlan
                  }
                  company={
                    plans.creative[planType === 'month' ? 'monthly' : 'yearly']
                      .custom.company
                  }
                  description={
                    plans.creative[planType === 'month' ? 'monthly' : 'yearly']
                      .custom.description
                  }
                  onSelect={() => {
                    setAnalytics('creative', planType);

                    return onRedirectToCheckout(
                      plans.creative[
                        planType === 'month' ? 'monthly' : 'yearly'
                      ].id,
                      planType === 'month' ? 'monthly' : 'yearly'
                    );
                  }}
                ></PlanContainer>
              )}

            {payment.plans &&
              payment.plans.length > 0 &&
              plans &&
              plans.professional &&
              plans.professional?.monthly?.plans &&
              plans.professional?.yearly?.plans && (
                <PlanContainer
                  nickname={
                    plans.professional[
                      planType === 'month' ? 'monthly' : 'yearly'
                    ].plans[word].nickname
                  }
                  key={
                    plans.professional[
                      planType === 'month' ? 'monthly' : 'yearly'
                    ].plans[word].id
                  }
                  title={
                    plans.professional[
                      planType === 'month' ? 'monthly' : 'yearly'
                    ].custom.title
                  }
                  subtitle={
                    plans.professional[
                      planType === 'month' ? 'monthly' : 'yearly'
                    ].custom.subtitle
                  }
                  price={
                    plans.professional[
                      planType === 'month' ? 'monthly' : 'yearly'
                    ].plans[word].amount
                  }
                  yearlyMessage={
                    plans.professional[
                      planType === 'month' ? 'monthly' : 'yearly'
                    ].plans[word].yearlyMessage
                      ? plans.professional[
                          planType === 'month' ? 'monthly' : 'yearly'
                        ].plans[word].yearlyMessage
                      : null
                  }
                  type={planType}
                  word={word}
                  setWord={setWord}
                  benefits={
                    plans.professional[
                      planType === 'month' ? 'monthly' : 'yearly'
                    ].custom.benefits
                  }
                  bestPlan={
                    plans.professional[
                      planType === 'month' ? 'monthly' : 'yearly'
                    ].custom.bestPlan
                  }
                  company={
                    plans.professional[
                      planType === 'month' ? 'monthly' : 'yearly'
                    ].custom.company
                  }
                  description={
                    plans.professional[
                      planType === 'month' ? 'monthly' : 'yearly'
                    ].custom.description
                  }
                  onSelect={() => {
                    setAnalytics('professional', planType);

                    return onRedirectToCheckout(
                      plans.professional[
                        planType === 'month' ? 'monthly' : 'yearly'
                      ].plans[word].id,
                      planType === 'month' ? 'monthly' : 'yearly'
                    );
                  }}
                ></PlanContainer>
              )}

            {!isBlackWeek() &&
              payment.plans &&
              payment.plans.length > 0 &&
              plans &&
              plans.company && (
                <PlanContainer
                  key={
                    plans.company[planType === 'month' ? 'monthly' : 'yearly']
                      .id
                  }
                  title={
                    plans.company[planType === 'month' ? 'monthly' : 'yearly']
                      .custom.title
                  }
                  subtitle={
                    plans.company[planType === 'month' ? 'monthly' : 'yearly']
                      .custom.subtitle
                  }
                  price={
                    plans.company[planType === 'month' ? 'monthly' : 'yearly']
                      .price
                  }
                  type={planType}
                  benefits={
                    plans.company[planType === 'month' ? 'monthly' : 'yearly']
                      .custom.benefits
                  }
                  bestPlan={
                    plans.company[planType === 'month' ? 'monthly' : 'yearly']
                      .custom.bestPlan
                  }
                  company={
                    plans.company[planType === 'month' ? 'monthly' : 'yearly']
                      .custom.company
                  }
                  description={
                    plans.company[planType === 'month' ? 'monthly' : 'yearly']
                      .custom.description
                  }
                  onSelect={() => {
                    setAnalytics('company', planType);

                    window.open(
                      'https://form.typeform.com/to/kbyMgwPE',
                      '_blank'
                    );
                  }}
                ></PlanContainer>
              )}
          </>
        )}

        {getPlatformVersion(user) !== 'standard' && (
          <>
            {payment.plans &&
              payment.plans.length > 0 &&
              plans &&
              plans.lite &&
              plans.lite?.monthly && (
                <PlanContainer
                  nickname={plans.lite.monthly.nickname}
                  key={plans.lite.monthly.id}
                  title={plans.lite.monthly.custom.title}
                  subtitle={''}
                  price={plans.lite.monthly.amount}
                  yearlyMessage={''}
                  type={planType}
                  benefits={[]}
                  bestPlan={plans.lite.monthly.custom.bestPlan}
                  company={plans.lite.monthly.custom.company}
                  description={''}
                  onSelect={() => {
                    setAnalytics('education', 'monthly');

                    return onRedirectToCheckout(
                      plans.lite.monthly.id,
                      'monthly'
                    );
                  }}
                ></PlanContainer>
              )}

            {payment.plans &&
              payment.plans.length > 0 &&
              plans &&
              plans.lite &&
              plans.lite?.yearly && (
                <PlanContainer
                  nickname={plans.lite.yearly.nickname}
                  key={plans.lite.yearly.id}
                  title={plans.lite.yearly.custom.title}
                  subtitle={''}
                  price={plans.lite.yearly.amount}
                  type={planType}
                  benefits={[]}
                  bestPlan={plans.lite.yearly.custom.bestPlan}
                  company={plans.lite.yearly.custom.company}
                  description={''}
                  yearlyMessage={plans.lite.yearly.yearlyMessage}
                  onSelect={() => {
                    setAnalytics('education', 'yearly');

                    return onRedirectToCheckout(plans.lite.yearly.id, 'yearly');
                  }}
                ></PlanContainer>
              )}

            {payment.plans &&
              payment.plans.length > 0 &&
              plans &&
              plans.lite &&
              plans.lite?.semiAnnual && (
                <PlanContainer
                  nickname={plans.lite.semiAnnual.nickname}
                  key={plans.lite.semiAnnual.id}
                  title={plans.lite.semiAnnual.custom.title}
                  subtitle={''}
                  price={plans.lite.semiAnnual.amount}
                  type={'planType'}
                  benefits={[]}
                  bestPlan={plans.lite.semiAnnual.custom.bestPlan}
                  company={plans.lite.semiAnnual.custom.company}
                  description={''}
                  yearlyMessage={plans.lite.semiAnnual.yearlyMessage}
                  onSelect={() => {
                    setAnalytics('education', 'semiannual');

                    return onRedirectToCheckout(
                      plans.lite.semiAnnual.id,
                      'yearly'
                    );
                  }}
                ></PlanContainer>
              )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default Plans;
