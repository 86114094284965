import React, { useContext } from 'react';
import FullLogo from '../../../../../../../../../assets/images/jsx-icons/full-logo.js';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';
import { ThemeContext } from '../../../../../../../../../utils/context/ThemeContext';

const LogoNavBar = () => {
  const themeMode = useContext(ThemeContext);
  return (
    <Box position={'absolute'} left={'40px'}>
      <Link to={'/account/credits-billings'}>
        <FullLogo
          color={themeMode.mode === 'light' ? 'primary' : 'primary'}
          style={{ width: '110px' }}
        />
      </Link>
    </Box>
  );
};

export default LogoNavBar;
