import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Container,
  Box,
  Link
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const faqData = [
  {
    question: 'Quero cancelar minha assinatura.',
    answer: (
      <>
        <Typography>
          Ah, que pena que você está nos deixando! Mas entendemos que, às vezes,
          é hora de seguir por outros caminhos.
        </Typography>
        <Typography>
          Para cancelar sua assinatura da Clarice, siga estes passos:
        </Typography>
        <ol style={{ paddingLeft: '45px' }}>
          <li>Faça login com seu e-mail e senha.</li>
          <li>
            No canto inferior esquerdo, passe o mouse sobre o seu nome e clique
            em <strong>&quot;Créditos e Cobranças&quot;</strong>.
          </li>
          <li>
            Clique em <strong>&quot;Cancelar Assinatura&quot;</strong> e siga as
            instruções.
          </li>
        </ol>
        <Typography>
          📌 Importante: Certifique-se de concluir todo o processo de
          cancelamento até aparecer a mensagem abaixo:
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <img
            src="/cancel-message.png"
            alt="Confirmação de cancelamento"
            style={{ width: '100%', maxWidth: '500px', borderRadius: '8px' }}
          />
        </Box>
        <Typography sx={{ mt: 2 }}>
          Se precisar de ajuda, envie um e-mail para{' '}
          <Link href="mailto:ola@clarice.ai">ola@clarice.ai</Link>
        </Typography>
      </>
    )
  },
  {
    question: 'Como faço para solicitar um reembolso?',
    answer: (
      <>
        <Typography>
          Sabemos que, às vezes, isso pode ser necessário. Para solicitar um
          reembolso, envie um e-mail para{' '}
          <Link href="mailto:ola@clarice.ai">ola@clarice.ai</Link> para
          analisarmos o seu caso.
        </Typography>
        <Typography>
          📌 Importante: Se a solicitação for feita até às 23h59 do dia da
          cobrança, você terá direito ao reembolso conforme nossa política.
        </Typography>
        <Typography>
          Antes de prosseguir, recomendamos que leia nossa{' '}
          <Link href="https://clarice.ai/reembolsos" target="_blank">
            Política de Reembolso
          </Link>{' '}
          para entender todos os detalhes. 💜
        </Typography>
      </>
    )
  },
  {
    question: 'Estou com problemas para usar a plataforma.',
    answer: (
      <>
        <Typography>
          Ah, isso não deveria acontecer! Para relatar um problema, envie um
          e-mail para <Link href="mailto:ola@clarice.ai">ola@clarice.ai</Link>{' '}
          com todos os detalhes do erro.
        </Typography>
        <Typography>
          🔹 Se puder, inclua prints ou gravações da tela para que nossa equipe
          técnica possa analisar e resolver o mais rápido possível. 💜
        </Typography>
      </>
    )
  },
  {
    question: 'Quero alterar o meu cadastro.',
    answer: (
      <>
        <Typography>
          Por questões de segurança, pedimos que qualquer solicitação de
          alteração de cadastro seja enviada para{' '}
          <Link href="mailto:ola@clarice.ai">ola@clarice.ai</Link>, informando
          os detalhes que deseja modificar.
        </Typography>
      </>
    )
  },
  {
    question: 'Preços e planos.',
    answer: (
      <>
        <Typography>
          Quer saber mais sobre nossos preços e planos?{' '}
          <Link href="https://clarice.ai/precos-planos" target="_blank">
            Clique aqui
          </Link>{' '}
          para conferir todas as opções. 💜
        </Typography>
      </>
    )
  }
];

const SupportPage = () => {
  return (
    <Container maxWidth="md" sx={{ my: 4 }}>
      <Box sx={{ textAlign: 'center', mb: 4 }}>
        <Typography variant="h4" gutterBottom>
          Qual é a sua dúvida?
        </Typography>
      </Box>
      {faqData.map((item, index) => (
        <Accordion
          key={index}
          sx={{ mb: 3, borderRadius: '8px', boxShadow: 2 }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ backgroundColor: '', borderRadius: '8px' }}
          >
            <Typography variant="h6">{item.question}</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ mt: 0, mb: 2, p: 2 }}>
            {item.answer}
          </AccordionDetails>
        </Accordion>
      ))}
      <Box sx={{ mt: 4, textAlign: 'center' }}>
        <Typography variant="body1">
          Ainda tem dúvidas? Entre em contato com nosso time de suporte pelo
          e-mail <Link href="mailto:ola@clarice.ai">ola@clarice.ai</Link>.
        </Typography>
      </Box>
    </Container>
  );
};

export default SupportPage;
