const LANGUAGE_PT: any = {
  configurator: {
    language: {
      title: 'Configurações de Idioma',
      options: {
        portuguese: 'Português',
        english: 'Inglês',
        spanish: 'Espanhol'
      }
    },
    platform: {
      title: 'Configurações de Plataforma',
      options: {
        standard: 'Padrão',
        education: 'Educação',
        agents: 'Agentes'
      }
    }
  },
  page: {
    profile: {
      title: 'Perfil',
      section: {
        info: {
          description: 'Seus Dados',
          name: 'Como podemos te chamar?',
          email: 'E-mail',
          phone: 'Telefone',
          action: {
            edit: {
              label: 'Editar Dados'
            }
          },
          uninformed: 'Não Informado'
        },
        general: {
          description: 'Minha Conta',
          firstAccess: 'Primeiro Acesso',
          texts: 'Textos',
          words: 'Palavras',
          suggestion: {
            title: 'Desvios de Estilo',
            options: {
              clarity: 'Clareza',
              conciseness: 'Concisão',
              strength: 'Força',
              originality: 'Originalidade',
              formatting: 'Formatação',
              correction: 'Correção'
            },
            corrected: 'desvios corrigidos'
          },
          uninformed: 'Não Informado',
          tag: {
            free: 'FREE',
            paid: 'PRO'
          }
        }
      }
    },
    editProfile: {
      title: 'Editar Perfil',
      description: 'Configurações do perfil do usuário',
      form: {
        name: {
          label: 'Nome',
          placeholder: 'Digite seu nome...'
        },
        email: {
          label: 'E-mail',
          placeholder: 'Digite seu e-mail...'
        },
        phone: {
          label: 'Telefone',
          placeholder: 'Digite seu telefone...',
          mask: '(99) 99999-9999'
        }
      },
      action: {
        update: 'Salvar'
      }
    },
    signIn: {
      title: 'Login',
      description:
        'Seus dados estão seguros com a gente, sob a proteção da LGPD. Acesse os termos de uso e as políticas de privacidade.',
      leftContainerFlow: {
        description:
          'Seus dados estão seguros com a gente, sob a proteção da LGPD. Acesse os termos de uso e as políticas de privacidade.'
      },
      form: {
        title: 'Acesse sua conta',
        email: {
          label: 'E-mail',
          placeholder: 'Insira seu e-mail'
        },
        password: {
          label: 'Senha',
          placeholder: 'Insira sua senha'
        },
        action: {
          signIn: 'Entrar',
          signUp: 'Crie uma conta',
          forgot: 'Esqueceu sua senha?',
          connected: 'Manter Conectado',
          firstAccess: 'Primeiro Acesso?',
          signInWithGoogle: 'Fazer Login com Google'
        },
        notify: {
          required: {
            email: 'O campo de e-mail é obrigatório.',
            password: 'O campo de senha é obrigatório.'
          }
        }
      }
    },
    signUp: {
      title: 'Crie sua conta',
      description:
        'Seus dados estão seguros com a gente, sob a proteção da LGPD. Acesse os termos de uso e as políticas de privacidade.',
      leftContainerFlow: {
        description:
          'Seus dados estão seguros com a gente, sob a proteção da LGPD. Acesse os termos de uso e as políticas de privacidade.'
      },
      form: {
        title: 'Crie sua conta',
        name: {
          label: 'Como podemos te chamar?',
          placeholder: 'Insira seu nome'
        },
        email: {
          label: 'E-mail',
          placeholder: 'Insira seu e-mail'
        },
        password: {
          label: 'Senha',
          placeholder: 'Insira sua senha'
        },
        action: {
          signIn: 'Entrar',
          signUp: 'Criar conta',
          haveAnAccount: 'Já possui uma conta?',
          signUpWithGoogle: 'Cadastre-se com Google',
          acceptedTerms: {
            one: 'Li e aceito os',
            two: 'termos de uso',
            three: ',',
            four: 'política de privacidade',
            five: 'e',
            six: 'política de pagamento'
          }
        },
        notify: {
          required: {
            name: 'O campo de nome é obrigatório.',
            email: 'O campo de e-mail é obrigatório.',
            password: 'O campo de senha é obrigatório.',
            captcha: 'Por favor, realize o captcha.',
            accept: 'Por favor, aceite os termos.'
          }
        }
      }
    },
    forgotPassword: {
      title: 'Esqueci minha senha',
      description:
        'Seus dados estão seguros com a gente, sob a proteção da LGPD. Acesse os termos de uso e as políticas de privacidade.',
      leftContainerFlow: {
        description:
          'Seus dados estão seguros com a gente, sob a proteção da LGPD. Acesse os termos de uso e as políticas de privacidade.'
      },
      form: {
        title: 'Recupere sua senha',
        description:
          'Insira seu e-mail para receber instruções sobre como trocar sua senha por uma nova.',
        email: {
          label: 'E-mail',
          placeholder: 'Insira seu e-mail'
        },
        action: {
          sendEmail: 'Enviar e-mail de recuperação',
          signUp: 'Crie uma conta',
          firstAccess: 'Primeiro Acesso?'
        },
        notify: {
          required: {
            email: 'O campo de e-mail é obrigatório.'
          }
        }
      }
    },
    passwordRecovery: {
      title: 'Nova Senha',
      description:
        'Seus dados estão seguros com a gente, sob a proteção da LGPD. Acesse os termos de uso e as políticas de privacidade.',
      leftContainerFlow: {
        description:
          'Seus dados estão seguros com a gente, sob a proteção da LGPD. Acesse os termos de uso e as políticas de privacidade.'
      },
      form: {
        title: 'Falta só mais um passo!',
        description: 'Insira a nova senha para o seu acesso.',
        password: {
          label: 'Nova senha',
          placeholder: 'Insira sua nova senha'
        },
        action: {
          updatePassword: 'Atualizar minha senha'
        }
      }
    },
    project: {
      list: {
        title: 'Projetos',
        description: 'Aqui você encontra todos os seus projetos.',
        search: {
          label: 'Pesquisar',
          placeholder: 'Pesquise por um projeto...'
        },
        action: {
          create: 'Criar Projeto',
          update: 'Editar',
          delete: 'Excluir'
        },
        notFound: 'Nenhum projeto encontrado...',
        notAdded: 'Nenhum projeto adicionado até o momento...',
        notTitle: 'Sem título'
      },
      modal: {
        create: {
          title: 'Novo Projeto',
          description: 'Crie novos projetos para organizar seu trabalho.',
          form: {
            name: {
              label: 'Nome',
              placeholder: 'Insira um nome...'
            },
            description: {
              label: 'Descrição',
              placeholder: 'Insira uma descrição...'
            }
          },
          action: {
            cancel: 'Cancelar',
            confirm: 'Criar'
          }
        },
        update: {
          title: 'Editar Projeto',
          description: 'Atualizar o nome e a descrição do seu projeto.',
          form: {
            name: {
              label: 'Nome',
              placeholder: 'Insira um nome...'
            },
            description: {
              label: 'Descrição',
              placeholder: 'Insira uma descrição...'
            }
          },
          action: {
            cancel: 'Cancelar',
            confirm: 'Salvar'
          }
        },
        delete: {
          title: 'Excluir Projeto',
          description: 'Você tem certeza que deseja excluir este projeto?',
          action: {
            cancel: 'Cancelar',
            confirm: 'Excluir'
          }
        }
      }
    },
    brand: {
      list: {
        title: 'Central da Marca',
        search: {
          label: 'Pesquisar',
          placeholder: 'Pesquise por uma marca...'
        },
        action: {
          create: 'Criar marca',
          update: 'Editar',
          delete: 'Excluir'
        },
        notFound: 'Nenhuma marca encontrada....',
        notAdded: 'Nenhuma marca adicionada até o momento...',
        loading: 'Carregando...',
        notTitle: 'Sem título'
      },
      create: {
        title: 'Criar nova marca',
        description: {
          one: 'Bem-vindo à jornada de criação da sua marca! Aqui, você compartilhará as definições de comunicação do seu negócio.',
          two: 'Com essas informações valiosas em mãos, a Clarice.ai estará pronta para criar um guia único e memorável, alinhado com os objetivos e valores da sua marca.'
        },
        form: {
          name: {
            label: 'Nome',
            placeholder: 'Qual é o nome da sua marca? \nExemplo: Nike'
          },
          description: {
            label: 'Descrição',
            placeholder:
              'Conte um pouco sobre sua marca...\n\nExemplo: A Nike é uma marca global de roupas e calçados esportivos conhecida por sua inovação e qualidade.'
          },
          audience: {
            label: 'Audiência',
            placeholder:
              'Qual é o seu público-alvo? \n\nExemplo: Atletas, entusiastas do esporte, jovens adultos interessados em fitness.'
          },
          tone: {
            label: 'Tonalidade',
            placeholder:
              'Defina o tom de voz da sua marca. \n\nExemplo: Motivacional e inspirador.'
          },
          personality: {
            label: 'Personalidade',
            placeholder:
              'Escreva a personalidade da sua marca. \n\nExemplo: Uma marca ousada, corajosa e inspiradora, focada em atletas e pessoas que buscam superar seus limites.'
          },
          missionVisionAndValue: {
            label: 'Missão, Visão e Valores',
            placeholder:
              'Insira a missão, visão e valores da sua marca. \n\nMissão: Inspirar e inovar para todos os atletas no mundo. \nVisão: Trazer inspiração e inovação para todos os atletas. \nValores: Determinação, inovação, trabalho em equipe.'
          },
          keywords: {
            label: 'Palavras-chave',
            placeholder:
              'Insira palavras-chave relacionadas à sua marca. \n\nExemplo: Inovação, desempenho, superação.'
          },
          preferredWordsAndPhrases: {
            label: 'Palavras e Frases Preferidas',
            placeholder:
              'Liste as palavras e frases que você prefere usar em sua comunicação. \n\nExemplo: Just Do It, excelência, campeão.'
          },
          wordsAndPhrasesToAvoid: {
            label: 'Palavras e Frases Evitáveis',
            placeholder:
              'Liste as palavras e frases que devem ser evitadas. \n\nExemplo: Falha, desistir, impossível.'
          },
          image: {
            label: 'Insira uma imagem do logo da sua marca',
            placeholder: 'Insira uma imagem do logo da sua marca',
            chosen: 'Escolhido:',
            chooseImage: 'Escolher imagem'
          }
        },
        action: {
          generateWithIA: {
            label: 'Gerar com IA',
            tooltip:
              'Utilize nossa Inteligência Artificial e descreva sua marca em segundos!'
          },
          cancel: 'Voltar',
          confirm: 'Salvar'
        }
      },
      update: {
        title: 'Editar marca',
        form: {
          name: {
            label: 'Nome',
            placeholder: 'Qual é o nome da sua marca? \nExemplo: Nike'
          },
          description: {
            label: 'Descrição',
            placeholder:
              'Conte um pouco sobre sua marca...\n\nExemplo: A Nike é uma marca global de roupas e calçados esportivos conhecida por sua inovação e qualidade.'
          },
          audience: {
            label: 'Audiência',
            placeholder:
              'Qual é o seu público-alvo? \n\nExemplo: Atletas, entusiastas do esporte, jovens adultos interessados em fitness.'
          },
          tone: {
            label: 'Tonalidade',
            placeholder:
              'Defina o tom de voz da sua marca. \n\nExemplo: Motivacional e inspirador.'
          },
          personality: {
            label: 'Personalidade',
            placeholder:
              'Escreva a personalidade da sua marca. \n\nExemplo: Uma marca ousada, corajosa e inspiradora, focada em atletas e pessoas que buscam superar seus limites.'
          },
          missionVisionAndValue: {
            label: 'Missão, Visão e Valores',
            placeholder:
              'Insira a missão, visão e valores da sua marca. \n\nMissão: Inspirar e inovar para todos os atletas no mundo. \nVisão: Trazer inspiração e inovação para todos os atletas. \nValores: Determinação, inovação, trabalho em equipe.'
          },
          keywords: {
            label: 'Palavras-chave',
            placeholder:
              'Insira palavras-chave relacionadas à sua marca. \n\nExemplo: Inovação, desempenho, superação.'
          },
          preferredWordsAndPhrases: {
            label: 'Palavras e Frases Preferidas',
            placeholder:
              'Liste as palavras e frases que você prefere usar em sua comunicação. \n\nExemplo: Just Do It, excelência, campeão.'
          },
          wordsAndPhrasesToAvoid: {
            label: 'Palavras e Frases Evitáveis',
            placeholder:
              'Liste as palavras e frases que devem ser evitadas. \n\nExemplo: Falha, desistir, impossível.'
          },
          image: {
            label: 'Insira uma imagem do logo da sua marca',
            placeholder: 'Insira uma imagem do logo da sua marca',
            chosen: 'Escolhido:',
            chooseImage: 'Escolher imagem'
          }
        },
        action: {
          cancel: 'Voltar',
          confirm: 'Salvar'
        }
      },
      generateWithIA: {
        title: 'Preencha o campo abaixo e veja a mágica acontecer',
        description:
          'A inteligência artificial da Clarice.ai está pronta para criar uma marca incrível para você.',
        form: {
          description: {
            label: 'Descrição da marca',
            placeholder:
              'Descreva as principais características da sua marca...'
          }
        },
        action: {
          generate: 'Gerar com IA'
        }
      },
      modal: {
        delete: {
          title: 'Excluir Marca',
          description: 'Você tem certeza que deseja excluir esta marca?',
          action: {
            cancel: 'Cancelar',
            confirm: 'Excluir'
          }
        }
      }
    },
    business: {
      title: 'Meu Negócio',
      form: {
        name: {
          label: 'Nome',
          placeholder: 'Insira o nome do seu negócio...'
        }
      },
      action: {
        create: 'Cadastrar',
        update: 'Salvar'
      },
      notPermission: 'Você não tem permissão para acessar essa página...',
      list: {
        title: 'Usuários',
        action: {
          add: 'Adicionar Usuário',
          delete: 'Excluir'
        },
        name: 'Nome',
        email: 'E-mail',
        lastAccess: 'Último Acesso',
        notAdded: 'Nenhum usuário adicionado até o momento...',
        badge: {
          owner: 'Proprietário',
          active: 'Ativo',
          pending: 'Pendente'
        },
        licenses: {
          of: 'de',
          used: 'licenças utilizadas'
        }
      },
      modal: {
        add: {
          title: 'Adicionar Usuário',
          description: 'Adicione um usuário ao seu negócio agora mesmo!',
          form: {
            email: {
              label: 'Informe o e-mail do usuário'
            }
          },
          action: {
            cancel: 'Cancelar',
            confirm: 'Enviar Convite'
          }
        }
      }
    },
    backoffice: {
      title: 'Backoffice',
      notPermission: 'Você não tem permissão para acessar essa página...',
      business: {
        dashboard: {
          badge: {
            total: 'Total',
            active: 'Ativos',
            expired: 'Expirados'
          }
        },
        list: {
          title: 'Empresas',
          action: {
            create: 'Adicionar',
            update: 'Editar',
            delete: 'Excluir'
          },
          company: 'Empresa',
          plan: 'Plano',
          startDate: 'Data Inicial',
          endDate: 'Data Final',
          notAdded: 'Nenhuma empresa adicionada até o momento...',
          loading: 'Carregando...',
          badge: {
            total: 'Total',
            active: 'Ativo',
            expired: 'Expirado',
            pending: 'Pendente'
          },
          licenses: {
            of: 'de',
            used: 'licenças utilizadas'
          }
        },
        modal: {
          add: {
            title: 'Adicionar Empresa',
            description: 'Crie uma empresa e vincule a um usuário agora mesmo!',
            form: {
              name: {
                label: 'Nome da empresa',
                placeholder: ''
              },
              email: {
                label: 'E-mail do proprietário',
                placeholder: ''
              },
              licenses: {
                label: 'Quantidade de licenças',
                placeholder: ''
              },
              startDate: {
                label: 'Data Inicial',
                placeholder: ''
              },
              endDate: {
                label: 'Data Final',
                placeholder: ''
              },
              sharedBrand: {
                label: 'Manual da Marca',
                placeholder: ''
              }
            },
            action: {
              cancel: 'Cancelar',
              confirm: 'Criar'
            }
          }
        },
        update: {
          title: 'Editar Empresa',
          form: {
            name: {
              label: 'Nome da empresa',
              placeholder: ''
            },
            licenses: {
              label: 'Quantidade de licenças',
              placeholder: ''
            },
            startDate: {
              label: 'Data Inicial',
              placeholder: ''
            },
            endDate: {
              label: 'Data Final',
              placeholder: ''
            },
            sharedBrand: {
              label: 'Manual da Marca',
              placeholder: ''
            }
          },
          action: {
            confirm: 'Salvar'
          },
          user: {
            list: {
              title: 'Usuários',
              action: {
                add: 'Adicionar Usuário',
                delete: 'Excluir'
              },
              name: 'Nome',
              email: 'E-mail',
              lastAccess: 'Último Acesso',
              notAdded: 'Nenhum usuário adicionado até o momento...',
              badge: {
                owner: 'Proprietário',
                active: 'Ativo',
                pending: 'Pendente'
              },
              licenses: {
                of: 'de',
                used: 'licenças utilizadas'
              }
            },
            modal: {
              add: {
                title: 'Adicionar Usuário',
                description: 'Adicione um usuário ao negócio agora mesmo!',
                form: {
                  email: {
                    label: 'Informe o e-mail do usuário'
                  }
                },
                action: {
                  cancel: 'Cancelar',
                  confirm: 'Salvar'
                }
              }
            }
          }
        }
      }
    },
    credits: {
      title: 'Créditos e Cobrança',
      available: 'Créditos disponíveis',
      total: 'Totdal de Créditos',
      used: 'Créditos Utilizados',
      plan: 'Créditos do Plano',
      bonus: 'Créditos de Bônus',
      percentage: {
        standard: '% de créditos utilizados',
        plan: '% de créditos utilizados do plano',
        bonus: '% de créditos utilizados de bônus'
      },
      signature: {
        title: 'Plano',
        badge: {
          free: 'FREE',
          paid: 'PRO',
          active: 'Ativo',
          expired: 'Expirado'
        },
        active: {
          description:
            'Melhore sua conta para ter acesso a mais créditos bônus.'
        },
        canceled: {
          one: 'Após esse período você não terá mais acesso aos benefícios PRO.',
          two: 'Primeira',
          three: 'Próxima',
          four: 'cobrança'
        },
        trial: {
          one: 'Seu período de teste termina em',
          two: 'dias e ',
          three: 'horas.',
          four: 'A próxima renovação é em',
          five: 'dias'
        },
        action: {
          cancel: 'Cancelar Assinatura',
          get: 'Obter Plano',
          manage: 'Gerenciar Assinatura'
        }
      }
    },
    templates: {
      title: 'Templates',
      options: {
        all: 'Todos',
        ads: 'Ads',
        blog: 'Blog',
        ecommerce: 'Ecommerce',
        email: 'E-mail',
        frameworks: 'Frameworks',
        google: 'Google',
        marketing: 'Marketing',
        seo: 'SEO',
        socialMedia: 'Social Media',
        videos: 'Videos',
        website: 'Website',
        tools: 'Ferramentas',
        others: 'Outros'
      },
      list: {
        contentSummarizer: {
          title: 'Resumidor de Conteúdo',
          description: 'Obtenha um resumo com os pontos-chave de um texto.',
          fields: {
            content: {
              label: 'Conteúdo',
              placeholder: 'Insira o conteúdo a ser resumido...'
            },
            count: {
              label: 'Tamanho',
              placeholder: 'Para quantas palavras deseja resumir?',
              options: {
                '50': '50 palavras',
                '100': '100 palavras',
                '200': '200 palavras',
                '300': '300 palavras',
                '400': '400 palavras'
              }
            }
          }
        },
        facebookAdsDescription: {
          title: 'Anúncio para Facebook',
          description:
            'Crie anúncios para o Facebook que estimulem seus clientes a clicarem e comprarem.',
          fields: {
            name: {
              label: 'Nome da Empresa ou Produto',
              placeholder: 'Exemplo: Clarice.ai'
            },
            description: {
              label: 'Conte sobre seu produto ou serviço',
              placeholder: 'Exemplo: Corretor de texto inteligente'
            },
            audience: {
              label: 'Qual é o seu público-alvo?',
              placeholder: 'Exemplo: Escritores'
            },
            tone: {
              label: 'Tom de Voz',
              placeholder: 'Exemplo: Casual e criativo'
            }
          }
        },
        subtitleInstagram: {
          title: 'Legenda para Instagram',
          description:
            'Escreva legendas cativantes para suas postagens no Instagram.',
          fields: {
            description: {
              label: 'Sobre o que é sua postagem?',
              placeholder: 'Exemplo: Linha de rações para Pets'
            },
            tone: {
              label: 'Tom de Voz',
              placeholder: 'Exemplo: Casual e criativo'
            }
          }
        },
        blogPostIntro: {
          title: 'Introdução de Blog Post',
          description:
            'Supere o bloqueio da página em branco com uma introdução para seu blog.',
          fields: {
            title: {
              label: 'Título',
              placeholder:
                'Exemplo: Cinco novos modelos de carros elétricos que serão lançados no próximo ano.'
            },
            audience: {
              label: 'Audiência',
              placeholder:
                'Exemplo: Pessoas interessadas em veículos ecológicos'
            },
            tone: {
              label: 'Tom de Voz',
              placeholder: 'Exemplo: Casual e criativo'
            }
          }
        },
        blogPostConclusion: {
          title: 'Conclusão de Blog Post',
          description:
            'Finalize suas postagens de blog com uma conclusão envolvente.',
          fields: {
            title: {
              label: 'Título',
              placeholder:
                'Exemplo: Cinco novos modelos de carros elétricos que serão lançados no próximo ano.'
            },
            audience: {
              label: 'Audiência',
              placeholder:
                'Exemplo: Pessoas interessadas em veículos ecológicos'
            },
            tone: {
              label: 'Tom de Voz',
              placeholder: 'Exemplo: Casual e criativo'
            }
          }
        },
        blogPostOutline: {
          title: 'Esboço para Blog Post',
          description:
            'Organize suas ideias e conceitos em listas detalhadas antes de escrever.',
          fields: {
            title: {
              label: 'Título',
              placeholder:
                'Exemplo: Cinco novos modelos de carros elétricos que serão lançados no próximo ano.'
            },
            tone: {
              label: 'Tom de Voz',
              placeholder: 'Exemplo: Casual e criativo'
            }
          }
        },
        blogPostTopicIdeas: {
          title: 'Ideias de Tópicos para Blog Post',
          description:
            'Gere novas ideias de tópicos para blogs que engajem os leitores.',
          fields: {
            name: {
              label: 'Nome da empresa/produto',
              placeholder: 'Exemplo: Clarice.ai'
            },
            description: {
              label: 'Descrição da empresa/produto',
              placeholder: 'Exemplo: Corretor de texto inteligente'
            },
            audience: {
              label: 'Audiência',
              placeholder: 'Exemplo: Profissionais de marketing'
            },
            tone: {
              label: 'Tom de Voz',
              placeholder: 'Exemplo: Casual e criativo'
            }
          }
        },
        commands: {
          title: 'Comandos',
          description:
            'Diga exatamente o que você quer que a Clarice.ai escreva usando comandos.',
          fields: {
            description: {
              label: 'Digite seu comando para a Clarice.ai',
              placeholder:
                'Exemplo: Narrar uma história sobre Max viajando para a lua no estilo de Morgan Freeman.'
            },
            content: {
              label: 'Você tem alguma informação adicional?',
              placeholder:
                'Exemplo: Max era um gato curioso que gostava de aventuras.'
            }
          }
        },
        companyBio: {
          title: 'Biografia da Empresa',
          description:
            'Descreva de forma envolvente a trajetória da sua empresa.',
          fields: {
            name: {
              label: 'Nome da empresa',
              placeholder: 'Exemplo: Clarice.ai'
            },
            description: {
              label: 'Informações da empresa',
              placeholder: 'Exemplo: Sobre o que é sua empresa?'
            },
            audience: {
              label: 'Audiência',
              placeholder: 'Exemplo: Escritores'
            },
            tone: {
              label: 'Tom de Voz',
              placeholder: 'Exemplo: Casual e criativo'
            }
          }
        },
        contentImprover: {
          title: 'Melhorar Conteúdo',
          description:
            'Reescreva seu conteúdo para torná-lo mais interessante.',
          fields: {
            content: {
              label: 'Conteúdo',
              placeholder:
                'Exemplo: Apresentamos soluções inovadoras que simplificam as operações empresariais...'
            },
            tone: {
              label: 'Tom de Voz',
              placeholder: 'Exemplo: Casual e criativo'
            }
          }
        },
        rewriteText: {
          title: 'Reescrever Texto',
          description:
            'Reescreva frases e parágrafos sem alterar o significado original.',
          fields: {
            content: {
              label: 'Conteúdo',
              placeholder:
                'Exemplo: Apresentamos soluções inovadoras que simplificam as operações empresariais...'
            },
            tone: {
              label: 'Tom de Voz',
              placeholder: 'Exemplo: Casual e criativo'
            }
          }
        },
        emailSubject: {
          title: 'Assuntos de E-mail',
          description: 'Escreva assuntos mais atraentes para o seu e-mail.',
          fields: {
            name: {
              label: 'Nome da empresa/produto',
              placeholder: 'Exemplo: Clarice.ai'
            },
            description: {
              label: 'Do que se trata o seu e-mail?',
              placeholder:
                'Exemplo: Apresentamos nosso novo software que utiliza IA...'
            },
            tone: {
              label: 'Tom de Voz',
              placeholder: 'Exemplo: Inteligente'
            }
          }
        },
        emailMarketing: {
          title: 'E-mail Marketing',
          description:
            'Desenvolva campanhas de e-mail marketing que convertem e fidelizam clientes.',
          fields: {
            name: {
              label: 'Assunto e Pré-header',
              placeholder: 'Digite até 50 caracteres para cada...'
            },
            description: {
              label: 'Texto do Cabeçalho (Hero)',
              placeholder: 'Digite até 70 caracteres...'
            },
            content: {
              label: 'Introdução (Após o “Olá, [NOME]!”)',
              placeholder: 'Digite até 3 linhas de texto...'
            },
            cta: {
              label: 'Botão CTA',
              placeholder:
                'Digite até quatro palavras, idealmente duas ou três...'
            }
          }
        },
        paragraphsGenerator: {
          title: 'Gerador de Parágrafos',
          description:
            'Permita que a Clarice.ai elabore parágrafos impressionantes para cativar seus leitores.',
          fields: {
            description: {
              label: 'Sobre o que é o seu parágrafo?',
              placeholder:
                'Exemplo: Quais são as melhores frutas de cada estação?'
            },
            keywords: {
              label: 'Palavras-chave',
              placeholder: 'Exemplo: Maçã, laranja'
            },
            tone: {
              label: 'Tom de Voz',
              placeholder: 'Exemplo: Informativo'
            }
          }
        },
        productDescription: {
          title: 'Descrição de Produto',
          description:
            'A Clarice.ai pode criar descrições mais elaboradas para seus produtos.',
          fields: {
            name: {
              label: 'Nome do produto',
              placeholder: 'Exemplo: Clarice.ai'
            },
            description: {
              label: 'Conte-me sobre o seu produto',
              placeholder:
                'Exemplo: Plataforma de correção de texto inteligente.'
            },
            audience: {
              label: 'Audiência',
              placeholder: 'Exemplo: Escritores'
            },
            tone: {
              label: 'Tom de Voz',
              placeholder: 'Exemplo: Casual e criativo'
            }
          }
        },
        subtitleTikTok: {
          title: 'Legenda para TikTok',
          description:
            'Escreva legendas cativantes para seus vídeos no TikTok.',
          fields: {
            description: {
              label: 'Sobre o que é o seu vídeo?',
              placeholder: 'Exemplo: Como criar um roteiro de viagens perfeito.'
            },
            tone: {
              label: 'Tom de Voz',
              placeholder: 'Exemplo: Casual e criativo'
            }
          }
        },
        carouselInstagram: {
          title: 'Carrossel do Instagram',
          description:
            'Crie conteúdo slide por slide para um carrossel no Instagram.',
          fields: {
            description: {
              label: 'Assunto principal',
              placeholder: 'Exemplo: Como criar coelhos.'
            },
            tone: {
              label: 'Tom de Voz',
              placeholder: 'Exemplo: Profissional'
            }
          }
        },
        titleYouTube: {
          title: 'Título para vídeo do YouTube',
          description:
            'Crie títulos otimizados e envolventes para seus vídeos no YouTube.',
          fields: {
            description: {
              label: 'Qual o assunto do vídeo?',
              placeholder: 'Exemplo: Animais mais fantásticos do planeta.'
            },
            tone: {
              label: 'Tom de Voz',
              placeholder: 'Exemplo: Casual e criativo'
            }
          }
        },
        tagsYouTube: {
          title: 'Tags para vídeo do YouTube',
          description: 'Gere tags para seus vídeos a partir de palavras-chave.',
          fields: {
            title: {
              label: 'Tópico ou título do vídeo',
              placeholder: 'Exemplo: Como fazer uma renda extra...'
            }
          }
        },
        youtubeTopicIdeas: {
          title: 'Ideias de tópicos para vídeo do YouTube',
          description:
            'Encontre temas interessantes para seus vídeos no YouTube.',
          fields: {
            description: {
              label: 'Qual o assunto do vídeo?',
              placeholder: 'Exemplo: Os mistérios dos elefantes da Tailândia.'
            },
            audience: {
              label: 'Público-alvo',
              placeholder: 'Exemplo: Qual é o seu público-alvo?'
            },
            tone: {
              label: 'Tom de Voz',
              placeholder: 'Exemplo: Casual e criativo'
            }
          }
        },
        descriptionYouTube: {
          title: 'Descrição para vídeo do YouTube',
          description:
            'Escreva descrições envolventes para seus vídeos no YouTube.',
          fields: {
            title: {
              label: 'Título do vídeo',
              placeholder:
                'Exemplo: Receitas deliciosas para iniciantes na culinária.'
            }
          }
        },
        youtubeOutline: {
          title: 'Esboço de Script para vídeo do YouTube',
          description:
            "Crie esboços de script para vídeos estilo 'Lista' ou 'Como fazer'.",
          fields: {
            title: {
              label: 'Título ou tópico do vídeo',
              placeholder: 'Exemplo: As 10 maiores lendas do futebol...'
            }
          }
        },
        youtubeIntroduction: {
          title: 'Introdução para vídeo do YouTube',
          description:
            'Crie uma introdução envolvente para capturar a atenção dos espectadores.',
          fields: {
            title: {
              label: 'Assunto ou título do vídeo',
              placeholder:
                'Exemplo: As melhores cobranças de falta da história do futebol.'
            }
          }
        },
        weeklyContentCalendar: {
          title: 'Calendário Semanal de Conteúdo',
          description:
            'Gere um calendário de conteúdo semanal baseado em palavras-chave ou tópicos principais.',
          fields: {
            keywords: {
              label: 'Palavras-chave ou tópico principal',
              placeholder: 'Exemplo: As tecnologias do futuro.'
            },
            tone: {
              label: 'Tom de Voz',
              placeholder: 'Exemplo: Casual e criativo'
            }
          }
        },
        commemorativeDate: {
          title: 'Datas Comemorativas',
          description:
            'Crie mensagens personalizadas para felicitações e datas comemorativas.',
          fields: {
            description: {
              label: 'Motivo da comemoração',
              placeholder: 'Exemplo: Dia das mães.'
            },
            tone: {
              label: 'Tom de Voz',
              placeholder: 'Exemplo: Criativo'
            }
          }
        },
        explainToAChild: {
          title: 'Explique a uma criança',
          description:
            'Reescreva o texto para torná-lo mais fácil de entender por crianças.',
          fields: {
            description: {
              label: 'O que deseja explicar?',
              placeholder: 'Exemplo: O que é o espaço sideral?'
            },
            count: {
              label: 'Grau de Escolaridade',
              placeholder: 'Selecione o grau de escolaridade',
              options: {
                '1': '1º ano',
                '2': '2º ano',
                '3': '3º ano',
                '5': '5º ano',
                '7': '7º ano',
                '9': '9º ano'
              }
            }
          }
        },
        domainNameGenerator: {
          title: 'Gerador de Nome de Domínio',
          description: 'Gere ideias de nomes de domínio sobre o seu nicho.',
          fields: {
            description: {
              label: 'Qual o seu nicho?',
              placeholder: 'Exemplo: Empresa de tecnologia'
            }
          }
        },
        citation: {
          title: 'Citações Inspiradoras',
          description:
            'Obtenha citações inspiradoras de grandes personalidades.',
          fields: {
            description: {
              label: 'Tema',
              placeholder: 'Exemplo: O valor da amizade...'
            },
            tone: {
              label: 'Tom de Voz',
              placeholder: 'Exemplo: Casual e criativo'
            }
          }
        },
        companySlogan: {
          title: 'Slogan para Empresas',
          description: 'A frase perfeita que representa e destaca seu negócio.',
          fields: {
            name: {
              label: 'Nome da empresa',
              placeholder: 'Exemplo: Clarice.ai'
            },
            description: {
              label: 'Descrição da empresa',
              placeholder: 'Exemplo: Corretor de texto inteligente'
            },
            tone: {
              label: 'Tom de Voz',
              placeholder: 'Exemplo: Casual e criativo'
            }
          }
        },
        growthIdeas: {
          title: 'Ideias de Crescimento',
          description:
            'Táticas de crescimento para ajudar sua empresa a crescer.',
          fields: {
            name: {
              label: 'Nome da empresa ou produto',
              placeholder: 'Exemplo: Clarice.ai'
            },
            description: {
              label: 'Conte sobre seu produto ou serviço',
              placeholder: 'Exemplo: Corretor de texto inteligente'
            },
            tone: {
              label: 'Tom de Voz',
              placeholder: 'Exemplo: Casual e criativo'
            }
          }
        },
        companyMission: {
          title: 'Missão da Empresa',
          description:
            'Uma declaração clara dos objetivos e propósitos da sua empresa.',
          fields: {
            name: {
              label: 'Nome da empresa ou produto',
              placeholder: 'Exemplo: Clarice.ai'
            },
            description: {
              label: 'Conte sobre a empresa',
              placeholder: 'Exemplo: Corretor de texto inteligente'
            }
          }
        },
        companyName: {
          title: 'Nome para Empresa ou Produto',
          description: 'Gere um nome único para o seu negócio ou produto.',
          fields: {
            description: {
              label: 'Conte mais sobre seu negócio ou produto',
              placeholder: 'Exemplo: Corretor de texto inteligente'
            },
            keywords: {
              label: 'Palavras-chave',
              placeholder: 'Exemplo: Corretor, Inteligência Artificial'
            }
          }
        },
        companyVision: {
          title: 'Visão da Empresa',
          description:
            'Uma visão clara para atrair as pessoas, clientes e funcionários certos.',
          fields: {
            name: {
              label: 'Nome da empresa',
              placeholder: 'Exemplo: Clarice.ai'
            },
            description: {
              label: 'Descrição da empresa',
              placeholder: 'Exemplo: Corretor de texto inteligente'
            },
            tone: {
              label: 'Tom de Voz',
              placeholder: 'Exemplo: Casual e criativo'
            }
          }
        },
        facebookAdsTitle: {
          title: 'Título para Facebook Ads',
          description:
            'Crie títulos de alto desempenho para seus anúncios no Facebook.',
          fields: {
            name: {
              label: 'Nome da Empresa ou Produto',
              placeholder: 'Exemplo: Clarice.ai'
            },
            description: {
              label: 'Conte sobre seu produto ou serviço',
              placeholder: 'Exemplo: Corretor de texto inteligente'
            },
            audience: {
              label: 'Qual é o seu público-alvo?',
              placeholder: 'Exemplo: Escritores'
            },
            tone: {
              label: 'Tom de Voz',
              placeholder: 'Exemplo: Casual e criativo'
            }
          }
        },
        seoHomepage: {
          title: 'Página Inicial (SEO)',
          description:
            'Escreva títulos otimizados e meta descrições para sua página inicial.',
          fields: {
            name: {
              label: 'Nome da empresa ou produto',
              placeholder: 'Exemplo: Clarice.ai'
            },
            description: {
              label: 'Descrição do produto ou serviço',
              placeholder: 'Exemplo: Corretor de texto inteligente'
            },
            keywords: {
              label: 'Palavras-chave',
              placeholder: 'Exemplo: Corretor, Inteligência Artificial'
            }
          }
        },
        seoServicePage: {
          title: 'Página de Serviço (SEO)',
          description:
            'Escreva títulos otimizados e meta descrições para sua página de serviços.',
          fields: {
            name: {
              label: 'Nome da Empresa',
              placeholder: 'Exemplo: Clarice.ai'
            },
            theme: {
              label: 'Nome do serviço',
              placeholder: 'Exemplo: Clarice.ai Escritora'
            },
            description: {
              label: 'Descrição do serviço',
              placeholder: 'Exemplo: Corretor de texto inteligente'
            },
            keywords: {
              label: 'Palavras-chave',
              placeholder: 'Exemplo: Corretor, Inteligência Artificial'
            }
          }
        },
        seoProductPage: {
          title: 'Página de Produto (SEO)',
          description:
            'Escreva títulos otimizados e meta descrições para suas páginas de produtos.',
          fields: {
            name: {
              label: 'Nome da Empresa',
              placeholder: 'Exemplo: Clarice.ai'
            },
            theme: {
              label: 'Nome do Produto',
              placeholder: 'Exemplo: Clarice.ai Escritora'
            },
            description: {
              label: 'Descrição do produto',
              placeholder: 'Exemplo: Corretor de texto inteligente'
            },
            keywords: {
              label: 'Palavras-chave',
              placeholder: 'Exemplo: Corretor, Inteligência Artificial'
            }
          }
        },
        seoBlogPost: {
          title: 'Postagem de Blog (SEO)',
          description:
            'Escreva títulos otimizados e meta descrições para seus posts de blog.',
          fields: {
            name: {
              label: 'Nome da Empresa ou Produto',
              placeholder: 'Exemplo: Clarice.ai'
            },
            title: {
              label: 'Título da postagem',
              placeholder: 'Exemplo: O que é a Clarice.ai?'
            },
            description: {
              label: 'Descrição da postagem',
              placeholder:
                'Exemplo: A Clarice.ai é um corretor de texto inteligente...'
            }
          }
        },
        linkedinAdsTitle: {
          title: 'Título para LinkedIn Ads',
          description: 'Crie títulos profissionais para anúncios no LinkedIn.',
          fields: {
            name: {
              label: 'Nome da Empresa ou Produto',
              placeholder: 'Exemplo: Clarice.ai'
            },
            description: {
              label: 'Conte sobre seu produto ou serviço',
              placeholder: 'Exemplo: Corretor de texto inteligente'
            },
            audience: {
              label: 'Qual é o seu público-alvo?',
              placeholder: 'Exemplo: Escritores'
            },
            tone: {
              label: 'Tom de Voz',
              placeholder: 'Exemplo: Casual e criativo'
            }
          }
        },
        linkedinAdsDescription: {
          title: 'Descrição para LinkedIn Ads',
          description: 'Crie descrições envolventes para anúncios no LinkedIn.',
          fields: {
            name: {
              label: 'Nome da Empresa ou Produto',
              placeholder: 'Exemplo: Clarice.ai'
            },
            description: {
              label: 'Conte sobre seu produto ou serviço',
              placeholder: 'Exemplo: Corretor de texto inteligente'
            },
            audience: {
              label: 'Qual é o seu público-alvo?',
              placeholder: 'Exemplo: Escritores'
            },
            tone: {
              label: 'Tom de Voz',
              placeholder: 'Exemplo: Casual e criativo'
            }
          }
        },
        linkedinPost: {
          title: 'Postagem no LinkedIn',
          description: 'Crie postagens envolventes e inspiradoras no LinkedIn.',
          fields: {
            description: {
              label: 'Sobre o que é sua postagem?',
              placeholder: 'Exemplo: Como a IA irá impactar as profissões.'
            },
            audience: {
              label: 'Qual é o seu público-alvo?',
              placeholder: 'Exemplo: Escritores'
            },
            cta: {
              label: 'Ação desejada',
              placeholder: 'Exemplo: Marcar um amigo no post'
            }
          }
        },
        twitterPost: {
          title: 'Postagem para Twitter',
          description:
            'Gere tweets criativos e envolventes para aumentar sua presença no Twitter.',
          fields: {
            description: {
              label: 'Sobre o que é sua postagem?',
              placeholder:
                'Exemplo: Nova galáxia em formação descoberta por telescópio espacial...'
            },
            tone: {
              label: 'Tom de Voz',
              placeholder: 'Exemplo: Casual e criativo'
            }
          }
        },
        googleAdsTitle: {
          title: 'Título para Google Ads',
          description:
            'Crie títulos de alta conversão para seus anúncios no Google.',
          fields: {
            name: {
              label: 'Nome da Empresa ou Produto',
              placeholder: 'Exemplo: Clarice.ai'
            },
            description: {
              label: 'Conte sobre seu produto ou serviço',
              placeholder: 'Exemplo: Corretor de texto inteligente'
            },
            audience: {
              label: 'Qual é o seu público-alvo?',
              placeholder: 'Exemplo: Escritores'
            },
            tone: {
              label: 'Tom de Voz',
              placeholder: 'Exemplo: Casual e criativo'
            }
          }
        },
        googleAdsDescription: {
          title: 'Descrição para Google Ads',
          description:
            'Crie descrições de alta conversão para seus anúncios no Google.',
          fields: {
            name: {
              label: 'Nome da Empresa ou Produto',
              placeholder: 'Exemplo: Clarice.ai'
            },
            description: {
              label: 'Conte sobre seu produto ou serviço',
              placeholder: 'Exemplo: Corretor de texto inteligente'
            },
            audience: {
              label: 'Qual é o seu público-alvo?',
              placeholder: 'Exemplo: Escritores'
            },
            tone: {
              label: 'Tom de Voz',
              placeholder: 'Exemplo: Casual e criativo'
            }
          }
        },
        resourcesForBenefits: {
          title: 'Recursos para Benefícios',
          description:
            'Transforme os recursos do seu produto em benefícios que motivam a ação.',
          fields: {
            description: {
              label: 'Descrição do Produto',
              placeholder:
                'Exemplo: Nós simplificamos a automação de tarefas para agências.'
            }
          }
        },
        imagePrompt: {
          title: 'Prompt para Imagens',
          description: 'Crie prompts de imagem para usar com Clarice.ai Arte.',
          fields: {
            description: {
              label: 'Ideia geral ou tema',
              placeholder: 'Exemplo: Um gato de botas amarelas'
            },
            tone: {
              label: 'Tom de Voz',
              placeholder: 'Exemplo: Profissional'
            }
          }
        },
        personalBiography: {
          title: 'Biografia Pessoal',
          description:
            'Crie uma biografia que destaque suas realizações e chame a atenção.',
          fields: {
            name: {
              label: 'Qual seu nome?',
              placeholder: 'Exemplo: Felipe'
            },
            description: {
              label: 'Informações Pessoais',
              placeholder:
                'Exemplo: Detalhes sobre sua família, educação, e ocupações relevantes.'
            },
            pointsOfView: {
              label: 'Ponto de Vista',
              placeholder: 'Exemplo: Primeira Pessoa ou Terceira Pessoa'
            },
            tone: {
              label: 'Tom de Voz',
              placeholder: 'Exemplo: Profissional'
            }
          }
        },
        appNotifications: {
          title: 'Notificações de Aplicativos e SMS',
          description:
            'Crie notificações que façam seus usuários retornarem ao seu app ou produto.',
          fields: {
            description: {
              label: 'Descreva a notificação',
              placeholder:
                'Exemplo: Lembrete para usar as funções de nosso aplicativo.'
            },
            tone: {
              label: 'Tom de Voz',
              placeholder: 'Exemplo: Profissional'
            }
          }
        },
        pushNotification: {
          title: 'Push Notification',
          description:
            'Crie notificações atrativas para manter seus usuários engajados com seu produto.',
          fields: {
            title: {
              label: 'Título',
              placeholder: 'Digite um título de até 30 caracteres...'
            },
            description: {
              label: 'Mensagem',
              placeholder: 'Digite uma mensagem de até 90 caracteres...'
            }
          }
        },
        aidaStructure: {
          title: 'AIDA Framework',
          description:
            'Use o framework de marketing AIDA: Atenção, Interesse, Desejo, Ação.',
          fields: {
            name: {
              label: 'Nome da Empresa ou Produto',
              placeholder: 'Exemplo: Clarice.ai'
            },
            description: {
              label: 'Descrição do Produto',
              placeholder: 'Exemplo: Corretor de texto inteligente'
            },
            tone: {
              label: 'Tom de Voz',
              placeholder: 'Exemplo: Profissional'
            }
          }
        },
        pasStructure: {
          title: 'PAS Framework',
          description:
            'Um framework poderoso para a criação de copy de marketing eficaz.',
          fields: {
            name: {
              label: 'Nome da Empresa ou Produto',
              placeholder: 'Exemplo: Clarice.ai'
            },
            description: {
              label: 'Descrição do Produto',
              placeholder: 'Exemplo: Corretor de texto inteligente'
            },
            tone: {
              label: 'Tom de Voz',
              placeholder: 'Exemplo: Profissional'
            }
          }
        },
        faqGenerator: {
          title: 'Gerador de Perguntas Frequentes',
          description:
            'Crie perguntas frequentes para suas postagens e páginas.',
          fields: {
            description: {
              label: 'Assunto Principal',
              placeholder: 'Exemplo: Curso de programação para iniciantes'
            },
            tone: {
              label: 'Tom de Voz',
              placeholder: 'Exemplo: Profissional'
            }
          }
        },
        engagingQuestions: {
          title: 'Perguntas Envolventes',
          description:
            'Crie perguntas criativas que aumentam o engajamento do seu público.',
          fields: {
            description: {
              label: 'Assunto',
              placeholder: 'Exemplo: Crescimento de adoção de criptomoedas'
            },
            audience: {
              label: 'Público-alvo',
              placeholder: 'Exemplo: Investidores'
            },
            tone: {
              label: 'Tom de Voz',
              placeholder: 'Exemplo: Profissional'
            }
          }
        },
        jobInterview: {
          title: 'Entrevista de Emprego',
          description:
            'Deixe a Clarice.ai criar perguntas para sua entrevista de emprego.',
          fields: {
            description: {
              label: 'Qual a vaga de emprego?',
              placeholder: 'Exemplo: Desenvolvedor'
            },
            tone: {
              label: 'Tom de Voz',
              placeholder: 'Exemplo: Profissional'
            }
          }
        },
        classPlan: {
          title: 'Plano de Aula',
          description:
            'Crie um plano de aula eficaz com uma estratégia clara de ensino.',
          fields: {
            content: {
              label: 'Conteúdo',
              placeholder: 'Exemplo: Curso de manutenção de aviões'
            },
            audience: {
              label: 'Público-alvo',
              placeholder: 'Exemplo: Mecânicos profissionais'
            },
            tone: {
              label: 'Tom de Voz',
              placeholder: 'Exemplo: Profissional'
            }
          }
        },
        perfectTitle: {
          title: 'Título Perfeito',
          description:
            'Crie títulos de alta conversão com técnicas de redatores globais.',
          fields: {
            theme: {
              label: 'Tema',
              placeholder: 'Exemplo: Inteligência Artificial'
            },
            description: {
              label: 'Assunto ou descrição do produto',
              placeholder:
                'Exemplo: Como a inteligência artificial otimiza seu tempo.'
            },
            audience: {
              label: 'Público-alvo',
              placeholder: 'Exemplo: Escritores'
            }
          }
        },
        customEmails: {
          title: 'Cold E-mails Personalizados',
          description:
            'Escreva cold e-mails personalizados que aumentam suas taxas de resposta.',
          fields: {
            name: {
              label: 'Nome da empresa ou produto',
              placeholder: 'Exemplo: Clarice.ai'
            },
            description: {
              label: 'Conte sobre sua empresa ou seu produto',
              placeholder:
                'Exemplo: A Clarice.ai é um corretor de texto inteligente'
            },
            content: {
              label: 'Qual o contexto ou objetivo do e-mail?',
              placeholder: 'Exemplo: Agendar uma demonstração da plataforma'
            },
            cta: {
              label: 'Ação desejada',
              placeholder:
                'Exemplo: O que você espera que o destinatário faça após ler o e-mail'
            },
            tone: {
              label: 'Tom de Voz',
              placeholder: 'Exemplo: Inteligente'
            }
          }
        },
        googleMyBusiness: {
          title: 'Google Meu Negócio',
          description:
            'Maximize sua visibilidade local com um perfil comercial Google otimizado.',
          fields: {
            location: {
              label: 'Onde está localizada?',
              placeholder: 'Exemplo: São Paulo'
            },
            name: {
              label: 'Nome da Empresa ou Produto',
              placeholder: 'Exemplo: Clarice.ai'
            },
            description: {
              label: 'O que vende/oferece?',
              placeholder: 'Exemplo: Plataforma de correção de texto'
            }
          }
        },
        listCreator: {
          title: 'Criador de Listas',
          description:
            'Gere listas numeradas e envolventes com base em um tópico.',
          fields: {
            theme: {
              label: 'Tema',
              placeholder: 'Exemplo: Tipos de bebidas mais caras do mundo'
            },
            count: {
              label: 'Quantidade de itens',
              placeholder: 'Quantos itens deseja gerar?',
              options: {
                '1': '1',
                '2': '2',
                '3': '3',
                '4': '4',
                '5': '5',
                '6': '6',
                '7': '7',
                '8': '8',
                '9': '9',
                '10': '10'
              }
            },
            tone: {
              label: 'Tom de Voz',
              placeholder: 'Exemplo: Inteligente'
            }
          }
        },
        analogyCreator: {
          title: 'Metaforizador',
          description:
            'Crie analogias exclusivas que tornam seu discurso mais memorável.',
          fields: {
            theme: {
              label: 'Sobre o que é a analogia?',
              placeholder: 'Exemplo: Os animais e as casas'
            },
            tone: {
              label: 'Tom de Voz',
              placeholder: 'Exemplo: Inteligente'
            }
          }
        },
        contentExpander: {
          title: 'Expandir Conteúdo',
          description:
            'Escreva a primeira frase e a Clarice.ai continua para você.',
          fields: {
            description: {
              label: 'Conteúdo para expandir',
              placeholder:
                'Exemplo: A escrita é uma das ferramentas mais poderosas...'
            },
            count: {
              label: 'Tamanho',
              placeholder: 'Quantas palavras deseja expandir?',
              options: {
                '50': '50 palavras',
                '100': '100 palavras',
                '200': '200 palavras',
                '300': '300 palavras',
                '400': '400 palavras'
              }
            },
            tone: {
              label: 'Tom de Voz',
              placeholder: 'Exemplo: Inteligente'
            }
          }
        },
        smartAnswers: {
          title: 'Respostas Inteligentes',
          description:
            'Responda perguntas difíceis de maneira clara e inteligente.',
          fields: {
            description: {
              label: 'Tópico ou Pergunta',
              placeholder: 'Exemplo: Como criar um servidor eficiente?'
            },
            tone: {
              label: 'Tom de Voz',
              placeholder: 'Exemplo: Profissional'
            }
          }
        },
        creativeStory: {
          title: 'História Criativa',
          description: 'Crie histórias criativas que encantem seus leitores.',
          fields: {
            theme: {
              label: 'Enredo ou tema',
              placeholder: 'Exemplo: Max era um gato curioso e destemido...'
            },
            tone: {
              label: 'Tom de Voz',
              placeholder: 'Exemplo: Inteligente'
            }
          }
        },
        uniqueValuePropositions: {
          title: 'Proposta de Valor Única',
          description:
            'Crie uma declaração poderosa que destaque os benefícios de sua oferta.',
          fields: {
            theme: {
              label: 'Descrição do negócio',
              placeholder:
                'Exemplo: Clarice.ai ajuda a simplificar a criação de conteúdo.'
            },
            audience: {
              label: 'Público-alvo',
              placeholder: 'Exemplo: Escritores'
            },
            description: {
              label: 'Problema que resolve',
              placeholder:
                'Exemplo: Facilita a escrita profissional para pequenas empresas.'
            },
            content: {
              label: 'Solução oferecida',
              placeholder: 'Exemplo: Criação de conteúdo eficiente e rápida.'
            }
          }
        },
        linkedinTopicIdeas: {
          title: 'Ideias de Tópicos para Postagem no LinkedIn',
          description:
            'Encontre temas interessantes para suas postagens no LinkedIn.',
          fields: {
            description: {
              label: 'Qual o assunto da sua postagem?',
              placeholder:
                'Exemplo: Como a IA está mudando o mercado de trabalho.',
              rows: 4
            },
            count: {
              label: 'Quantidade de ideias',
              placeholder: 'Quantas ideias você deseja gerar?',
              options: {
                '1': '1',
                '2': '2',
                '3': '3',
                '4': '4',
                '5': '5',
                '6': '6',
                '7': '7',
                '8': '8',
                '9': '9',
                '10': '10'
              }
            },
            audience: {
              label: 'Público-alvo',
              placeholder: 'Exemplo: Profissionais de marketing'
            },
            cta: {
              label: 'Ação desejada',
              placeholder: 'Exemplo: Marcar um colega no post'
            }
          }
        },
        instagramTopicIdeas: {
          title: 'Ideias de Reels para Instagram',
          description:
            'Descubra temas interessantes para criar reels envolventes no Instagram.',
          fields: {
            description: {
              label: 'Quais são os valores da sua marca?',
              placeholder: 'Exemplo: Inovação, Criatividade.'
            },
            count: {
              label: 'Quantidade de ideias',
              placeholder: 'Quantas ideias você deseja gerar?',
              options: {
                '1': '1',
                '2': '2',
                '3': '3',
                '4': '4',
                '5': '5',
                '6': '6',
                '7': '7',
                '8': '8',
                '9': '9',
                '10': '10'
              }
            },
            audience: {
              label: 'Público-alvo',
              placeholder: 'Exemplo: Jovens empreendedores'
            },
            cta: {
              label: 'Ação desejada',
              placeholder: 'Exemplo: Marcar um amigo no post'
            }
          }
        },
        instagramAdsDescription: {
          title: 'Anúncio para Instagram',
          description:
            'Crie anúncios cativantes para aumentar as conversões no Instagram.',
          fields: {
            theme: {
              label: 'Tema',
              placeholder: 'Exemplo: Inteligência Artificial'
            },
            description: {
              label: 'Assunto ou descrição do produto',
              placeholder:
                'Exemplo: Como a IA pode ajudar a criar melhores conteúdos.'
            },
            audience: {
              label: 'Público-alvo',
              placeholder: 'Exemplo: Profissionais de Marketing'
            },
            tone: {
              label: 'Tom de Voz',
              placeholder: 'Exemplo: Casual e criativo'
            }
          }
        },
        adsIdeas: {
          title: 'Ideias de Anúncios',
          description:
            'Receba sugestões de anúncios para sua marca, produto ou serviço.',
          fields: {
            name: {
              label: 'Nome da Empresa ou Produto',
              placeholder: 'Exemplo: Clarice.ai'
            },
            description: {
              label: 'Conte sobre seu produto ou serviço',
              placeholder: 'Exemplo: Corretor de texto inteligente'
            },
            audience: {
              label: 'Qual é o seu público-alvo?',
              placeholder: 'Exemplo: Escritores'
            },
            tone: {
              label: 'Tom de Voz',
              placeholder: 'Exemplo: Casual e criativo'
            }
          }
        }
      },
      badge: {
        shortly: 'Em breve'
      },
      action: {
        favorite: 'Marcar como favorito',
        unfavorite: 'Desmarcar como favorito',
        clean: 'Limpar',
        generate: 'Gerar',
        generateNewOutputs: {
          tooltip: 'Gerar Novas Saídas'
        },
        viewHistory: {
          tooltip: 'Visualizar Histórico'
        },
        clear: {
          tooltip: 'Limpar Saídas'
        },
        copy: {
          tooltip: 'Copiar Mensagem'
        },
        review: 'Revisar'
      },
      language: {
        title: 'Opções de idioma',
        input: {
          label: 'Idioma de Entrada',
          options: {
            portuguese: 'Português',
            english: 'Inglês',
            spanish: 'Espanhol'
          }
        },
        output: {
          label: 'Idioma de Saída',
          options: {
            portuguese: 'Português',
            english: 'Inglês',
            spanish: 'Espanhol'
          }
        }
      },
      formality: {
        label: 'Formalidade',
        options: {
          default: 'Padrão',
          lessFormal: 'Menos Formal',
          moreFormal: 'Mais Formal'
        }
      },
      quantity: {
        label: 'Quantidade',
        placeholder: 'Quantidade'
      },
      pointsOfView: {
        label: 'Ponto de Vista',
        options: {
          firstPerson: 'Primeira Pessoa',
          secondPerson: 'Terceira Pessoa'
        }
      },
      output: {
        title: 'Preencha os campos e veja a mágica acontecer',
        description: {
          one: 'Para obter os melhores resultados',
          two: 'tente diferentes entradas, de diferentes tamanhos.'
        }
      },
      historicNotFound: 'Nenhum histórico encontrado...'
    },
    chat: {
      title: 'Chat',
      description: {
        one: 'Escolha um prompt abaixo ou escreva o seu próprio para começar a conversar com a <strong>Clarice.ai</strong>.',
        two: 'Use esse chat para conversar com a <strong>Clarice.ai</strong> e pedir dicas de escrita. Para falar com o suporte, use o outro chat.'
      },
      form: {
        message: {
          label: 'Digite uma mensagem',
          placeholder: 'Digite uma mensagem...'
        }
      },
      action: {
        submit: 'Enviar',
        clean: 'Limpar',
        like: {
          tooltip: 'Boa resposta!'
        },
        dislike: {
          tooltip: 'Mensagem ruim!'
        },
        refresh: {
          tooltip: 'Tentar novamente'
        },
        copy: {
          tooltip: 'Copiar mensagem'
        },
        hide: {
          tooltip: 'Ocultar em Clarice.ai'
        },
        view: {
          tooltip: 'Mostrar em Clarice.ai'
        }
      },
      prompts: {
        title: 'Prompts',
        options: {
          one: 'Crie uma lista de 10 títulos de postagens de blog',
          two: 'Escreva um parágrafo sobre',
          three: 'Me ajude a criar um conto sobre',
          four: 'Escreva uma música sobre',
          five: 'Resuma o seguinte texto',
          six: 'Escreva um anúncio no Facebook para as festas de final de ano',
          seven: 'Escreva um post no blog sobre',
          eight: 'Desenvolva uma pequena história sobre'
        }
      }
    },
    documents: {
      search: {
        label: 'Pesquisar',
        placeholder: 'Pesquise por um título...'
      },
      action: {
        create: 'Novo Texto',
        update: 'Editar',
        delete: 'Excluir'
      },
      loading: 'Carregando...',
      notFound: 'Nenhum texto encontrado...',
      notAdded: 'Nenhum texto adicionado até o momento...',
      notAddedText:
        'Você ainda não adicionou nenhum texto aqui. Que tal começar agora?',
      updated: 'Atualizado',
      untitled: 'Sem título',
      modal: {
        delete: {
          title: 'Excluir Documento',
          description: 'Você tem certeza que deseja excluir este documento?',
          action: {
            cancel: 'Cancelar',
            confirm: 'Excluir'
          }
        }
      },
      limit: {
        free: {
          equal: {
            one: 'Você atingiu o limite de textos salvos. Para adicionar novos textos',
            two: 'torne-se PRO'
          },
          greater: ''
        },
        paid: {
          equal: {
            one: 'Você atingiu o limite de textos salvos. Por enquanto só consigo salvar',
            two: 'textos na versão PRO.'
          },
          greater: {
            one: 'Você já atingiu o limite de textos salvos. Para adicionar novos textos,',
            two: 'faça um upgrade'
          }
        }
      }
    },
    review: {
      form: {
        title: {
          label: 'Título',
          placeholder: 'Adicione um título.'
        },
        editor: {
          label: 'Editor',
          placeholder:
            'Cole seu texto aqui ou pressione "/" para escrever com IA'
        }
      },
      action: {
        viewDetails: 'Ver Detalhes',
        summary: 'Sumarização',
        copy: 'Copiar Texto',
        process: {
          label: 'Vamos lá',
          tooltip: 'Processar Texto',
          loading: 'Carregando'
        }
      },
      metrics: {
        words: 'Palavras',
        characters: 'Caracteres',
        sentences: 'Frases',
        paragraphs: 'Parágrafos'
      },
      score: {
        title: 'Score do Texto'
      },
      suggestion: {
        category: {
          all: {
            name: 'Todas as sugestões'
          },
          correction: {
            name: 'Correção',
            tooltip: 'Melhora a ortografia, gramática e pontuação.'
          },
          clarity: {
            name: 'Clareza',
            tooltip: 'Ajuda a tornar a sua escrita mais fácil de entender.'
          },
          conciseness: {
            name: 'Concisão',
            tooltip: 'Transmite a mensagem de forma direta e clara.'
          },
          strength: {
            name: 'Força',
            tooltip: 'Potencializa o impacto e a persuasão do seu texto.'
          },
          originality: {
            name: 'Originalidade',
            tooltip:
              'Auxilia na criação de um texto único e autêntico, oferecendo ideias frescas e criativas.'
          },
          premium: {
            name: 'PRO',
            tooltip:
              'Eleva sua escrita a um nível superior com uma correção impecável, clareza excepcional e resultados impactantes.'
          }
        }
      },
      card: {
        accept: 'Corrigido',
        dismiss: 'Dispensar',
        add: 'Adicionar',
        disable: 'Desativar Desvio',
        falsePositive: 'Falso Positivo'
      },
      language: {
        title: 'Idioma de Revisão',
        options: {
          portuguese: 'Português',
          english: 'Inglês',
          spanish: 'Espanhol'
        }
      }
    },
    tools: {
      title: 'Ferramentas',
      list: {
        review: {
          title: 'Revisão de Texto',
          description:
            'Melhore a qualidade do seu texto com sugestões de revisão.'
        },
        rewriteText: {
          title: 'Reescrever Texto',
          description: 'Reescreva o texto sem alterar o significado original.'
        },
        contentSummarizer: {
          title: 'Sumarizar Texto',
          description: 'Obtenha um resumo com os pontos-chave de um texto.'
        },
        translate: {
          title: 'Traduzir Texto',
          description: 'Traduza textos para diferentes idiomas.',
          fields: {
            content: {
              label: 'Texto',
              placeholder: 'Digite um cole um texto para traduzir...'
            },
            language: {
              input: {
                label: 'Idioma de Entrada',
                options: {
                  detect: 'Detectar Idioma',
                  portuguese: 'Português',
                  english: 'Inglês',
                  spanish: 'Espanhol'
                }
              },
              output: {
                label: 'Idioma de Saída',
                options: {
                  portuguese: 'Português',
                  english: 'Inglês',
                  spanish: 'Espanhol'
                }
              }
            }
          },
          action: {
            generate: 'Traduzir'
          }
        },
        aiDetect: {
          title: 'Detector de IA',
          description: 'Verifique se um texto foi gerado por uma IA.',
          fields: {
            content: {
              label: 'Texto',
              placeholder: 'Digite um cole um texto...'
            },
            language: {
              input: {
                label: 'Idioma de Entrada',
                options: {
                  detect: 'Detectar Idioma',
                  portuguese: 'Português',
                  english: 'Inglês',
                  spanish: 'Espanhol'
                }
              },
              output: {
                label: 'Idioma de Saída',
                options: {
                  portuguese: 'Português',
                  english: 'Inglês',
                  spanish: 'Espanhol'
                }
              }
            }
          },
          action: {
            generate: 'Analisar Texto'
          },
          result: {
            title: 'do texto é provavelmente gerado por IA',
            ai: 'Texto gerado por IA',
            human: 'Texto escrito por humano'
          }
        },
        plagiarism: {
          title: 'Verificador de Plágio',
          description: 'Verifique a originalidade de um texto.'
        }
      },
      badge: {
        shortly: 'Em breve'
      }
    },
    agents: {
      title: 'Agentes'
    },
    support: {
      title: 'Suporte'
    },
    plans: {
      title: 'Experimente grátis por 7 dias',
      description:
        'E encontre o plano mais adequado às necessidades do seu negócio',
      securePayment: 'Pagamento garantido e seguro',
      actions: {
        freeTrial: 'Iniciar teste grátis ➜',
        buyNow: 'Comprar agora ➜',
        contact: 'Entre em contato ➜'
      },
      consult: 'Consultar',
      customizedCharges: 'Cobranças personalizadas',
      loading: 'Carregando...',
      toggle: {
        monthly: 'Mensal',
        yearly: 'Anual'
      },
      bestPlan: 'Melhor Plano',
      saveMessage: '<strong>Economize 3 MESES</strong> no Plano Anual',
      billedAnnually: 'Cobrado anualmente',
      recurringPayment: 'Pagamento recorrente',
      perMonth: 'por mês no <b>Plano Mensal</b>',
      wordLimit: '100 mil palavras por mês',
      wordUnlimited: 'Sem limite de palavras',
      month: 'mês',
      currency: 'R$',
      notDecideNow: 'Não quero decidir agora',
      creative: {
        title: 'Criativo',
        subtitle:
          'Para criadores de contéudo que precisam escalar seu trabalho',
        description:
          'Com as sugestões avançadas da Clarice.ai, vá além da gramática e garanta uma escrita clara, concisa, forte e original.',
        benefits: {
          one: 'IA Revisora + IA Editora + IA Escritora',
          two: '65+ templates',
          three: 'ChatGPT',
          four: 'Plugin Google Docs',
          five: 'Até 50 textos',
          six: 'Até 10 projetos',
          seven: '1 Manual de Marca',
          eight: 'Suporte via e-mail'
        }
      },
      professional: {
        title: 'Profissional',
        subtitle:
          'Tudo o que você precisa para escrever profissionalmente 10x mais rápido',
        description:
          'Com as ferramentas de IA Revisora e IA Escritora, você tem à disposição poderosas soluções para escrever e revisar textos e conteúdos completos.',
        benefits: {
          one: 'IA Revisora + IA Editora + IA Escritora',
          two: '65+ templates',
          three: 'ChatGPT',
          four: 'Plugin Google Docs',
          five: 'Textos ilimitados',
          six: 'Projetos ilimitados',
          seven: 'Até 10 Manuais de Marca',
          eight: 'Suporte Premium'
        }
      },
      company: {
        title: 'Empresa',
        subtitle:
          'Para empresas que precisam de uma IA segura, escalável e feita sob medida',
        description:
          'Potencialize seus resultados com a Clarice.ai através de pacotes personalizados de créditos/usuários e suporte exclusivo.',
        benefits: {
          one: 'IA Revisora + IA Editora + IA Escritora',
          two: 'Manual da Marca',
          three: 'Mínimo de 5 membros',
          four: 'Cobrança Centralizada',
          five: 'Plugin Google Docs',
          six: 'Gerenciamento de Equipe',
          seven: 'Manuais de Marca Ilimitados',
          eight: 'Treinamento e Suporte Premium'
        }
      },
      lite: {
        monthly: {
          title: 'Mensal'
        },
        semiAnnual: {
          title: 'Semestral'
        },
        yearly: {
          title: 'Anual'
        }
      },
      discount: {
        ANUAL40: {
          description:
            'Utilize o cupom <b>ANUAL40</b> e ganhe 40% de desconto na assinatura anual. Aproveite! 🎉'
        },
        NIVER40: {
          description:
            'Utilize o cupom <b>NIVER40</b> e ganhe 40% de desconto na assinatura anual. Aproveite! 🎉'
        },
        NIVER60: {
          description:
            'Utilize o cupom <b>NIVER60</b> e ganhe 60% de desconto na assinatura. Aproveite! 🎉'
        }
      }
    }
  },
  standard: {
    channels: {
      one: 'Precisa de ajuda?',
      two: 'Visite nossos canais'
    },
    button: {
      back: 'Voltar'
    }
  },
  notify: {
    profile: {
      update: {
        success: 'Perfil atualizado com sucesso!'
      }
    },
    editProfile: {
      success: 'Dados cadastrais atualizados com sucesso!'
    },
    project: {
      create: {
        success: 'Projeto criado com sucesso!'
      },
      update: {
        success: 'Projeto atualizado com sucesso!'
      },
      delete: {
        success: 'Projeto excluído com sucesso!'
      }
    },
    brand: {
      create: {
        required: {
          all: 'Preencha todos os campos!'
        },
        loading: 'Criando a marca...',
        success: 'Marca criada com sucesso!',
        error: 'Algum erro aconteceu, tente novamente'
      },
      update: {
        required: {
          all: 'Preencha todos os campos!'
        },
        loading: 'Editando a marca...',
        success: 'Marca editada com sucesso!',
        error: 'Algum erro aconteceu, tente novamente'
      },
      delete: {
        success: 'Marca excluída com sucesso!'
      },
      generateWithIA: {
        required: {
          all: 'Preencha todos os campos!',
          description: 'Preencha o campo de descrição!'
        },
        loading: 'Criando a marca com IA..',
        success: 'Marca criada com sucesso!',
        error: 'Algum erro aconteceu, tente novamente'
      }
    },
    rule: {
      enable: {
        success: 'Regra reativada com sucesso!'
      }
    },
    dictionary: {
      add: {
        success: 'Palavra adicionada com sucesso!'
      },
      delete: {
        success: 'Palavra excluída com sucesso!'
      }
    },
    suggestion: {
      required: {
        all: 'Preencha os campos!'
      },
      success: 'Obrigada pela sugestão! :)'
    },
    business: {
      create: {
        success: 'Negócio criado com sucesso!'
      },
      update: {
        success: 'Negócio atualizado com sucesso!'
      },
      user: {
        add: {
          required: {
            all: 'Preencha os campos!'
          },
          success: 'Convite enviado com sucesso!'
        },
        delete: {
          success: 'Usuário removido com sucesso!'
        }
      }
    },
    backoffice: {
      business: {
        create: {
          required: {
            all: 'Preencha os campos!'
          },
          success: 'Empresa criada com sucesso!'
        },
        update: {
          required: {
            all: 'Preencha os campos!',
            name: 'Preencha o nome da empresa!',
            licenses: 'Preencha a quantidade de licenças!',
            startDate: 'Preencha a data inicial!',
            endDate: 'Preencha a data final!'
          },
          success: 'Empresa atualizada com sucesso!'
        },
        delete: {
          success: 'Empresa excluída com sucesso!'
        },
        user: {
          add: {
            required: {
              all: 'Preencha os campos!'
            },
            success: 'Usuário adicionado com sucesso!'
          },
          delete: {
            success: 'Usuário removido com sucesso!'
          }
        }
      }
    },
    templates: {
      create: {
        required: {
          all: 'Preencha todos os campos!'
        },
        loading: 'Processando resposta...',
        success: 'Resposta gerada com sucesso!',
        error: 'Algum erro aconteceu, tente novamente'
      },
      copyText: 'Texto copiado com sucesso!',
      createText: 'Seu texto foi criado!'
    },
    tools: {
      translate: {
        required: {
          all: 'Preencha todos os campos!'
        },
        loading: 'Traduzindo texto...',
        success: 'Texto traduzido com sucesso!',
        error: 'Algum erro aconteceu, tente novamente'
      },
      aiDetect: {
        required: {
          all: 'Preencha todos os campos!'
        },
        loading: 'Analisando texto...',
        success: 'Texto analisado com sucesso!',
        error: 'Algum erro aconteceu, tente novamente'
      },
      copyText: 'Texto copiado com sucesso!'
    },
    chat: {
      required: {
        message: {
          notEmpty: 'Digite uma mensagem!',
          maxLength: 'Mensagem muito grande, no máximo 2000 caracteres!'
        }
      },
      limit: 'Você atingiu o limite de créditos!'
    },
    documents: {
      delete: {
        success: 'Documento excluído com sucesso!',
        error: 'Ocorreu algum erro ao excluir seu documento, tente novamente.'
      }
    },
    mailConfirmation: {
      success: 'Cadastro confirmado com sucesso!'
    }
  },
  sidebar: {
    route: {
      home: 'Página Inicial',
      plans: 'Planos',
      templates: 'Templates',
      chat: 'Chat',
      editText: 'Edição de Texto',
      projects: 'Projetos',
      brands: 'Central da Marca',
      createBrand: 'Criar Marca',
      updateBrand: 'Editar Marca',
      updateBusiness: 'Editar Negócio',
      backoffice: 'Backoffice',
      review: 'Revisar Texto',
      rewriter: 'Reescrever Texto',
      summarizer: 'Sumarizar Texto',
      translate: 'Traduzir Texto',
      aiDetect: 'Detector de IA',
      disabledRules: 'Regras Desativadas',
      dictionary: 'Dicionário',
      account: {
        profile: 'Perfil',
        update: 'Editar Perfil',
        credits: 'Créditos e Cobrança',
        business: 'Meu Negócio'
      },
      help: 'Enviar Feedback',
      support: 'Suporte',
      documents: 'Documentos',
      agents: 'Agentes',
      agentCopyWriter: 'Copywriter',
      agentPhotographer: 'Fotógrafo',
      agentIllustrator: 'Ilustrador',
      agentMessages: 'Mensagens',
      agentService: 'Atendimento',
      agentRedactor: 'Redator',
      agentAcademic: 'Acadêmico'
    },
    projects: {
      title: 'Projetos',
      action: {
        view: 'Ver Projetos',
        new: 'Novo Projeto'
      }
    },
    brands: {
      title: 'Marcas',
      action: {
        view: 'Ver Marcas',
        new: 'Nova Marca'
      },
      notSelected: 'Não selecionada'
    },
    account: {
      profile: 'Perfil',
      business: 'Meu Negócio',
      backoffice: 'Backoffice',
      credits: 'Créditos e Cobrança',
      logout: 'Sair'
    },
    conversion: {
      generatedWords: 'Palavras geradas',
      paid: 'Faça um upgrade',
      free: 'Torne-se PRO',
      freeDays: '7 Dias Grátis'
    }
  },
  navbar: {
    useHistory: {
      mobile: 'Histórico',
      desktop: 'Utilizar Histórico'
    }
  },
  brand: {
    select: 'Selecione uma marca',
    tag: 'Marca selecionada para a criação de conteúdo.'
  },
  modal: {
    acceptedTerms: {
      title: 'Termos de Uso, Políticas de Privacidade e Pagamento',
      info: `<div><p> Olá! Gostaríamos de informá-lo que atualizamos recentemente nossos Termos de Uso, Política de Privacidade e Política de Pagamento. <br/> <br/> As mudanças foram realizadas para melhor refletir nossos serviços e garantir uma maior transparência e segurança para você, nosso usuário. </p> <strong>O que foi atualizado?</strong> <br/> <br/> <strong>Termos de Uso</strong>: Atualizamos nossos Termos de Uso para esclarecer os direitos e responsabilidades de todos os usuários da nossa plataforma. <br/> <br/> <strong>Política de Privacidade</strong>: Revisamos nossa Política de Privacidade para detalhar como coletamos, utilizamos e protegemos suas informações pessoais. <br/> <br/> <strong>Política de Pagamento</strong>: Fizemos ajustes na nossa Política de Pagamento para garantir clareza sobre os processos de cobrança, reembolsos e outros aspectos financeiros. <br /> <br /> Para continuar utilizando nossos serviços, pedimos que você leia e concorde com os novos termos. <br/> <br/> <strong>Próximos Passos</strong> <br/> <p style='margin-Left: 20px'> 1. Leia atentamente os novos Termos de Uso, Política de Privacidade e Política de Pagamento. <br/> 2. Role até o final deste documento. <br /> 3. Marque a caixa de seleção confirmando que você leu e concorda com os novos termos. <br/> 4. Clique no botão Aceitar. </p> Se você tiver alguma dúvida ou precisar de mais informações, entre em contato com nosso suporte ao cliente. <br/> <br/> Agradecemos por continuar utilizando a Clarice.ai.</>`,
      checkBox: {
        one: 'Li e aceito os',
        two: 'termos de uso',
        three: 'política de privacidade',
        four: 'política de pagamento'
      },
      action: {
        confirm: 'Aceitar'
      }
    },
    disabledRules: {
      title: 'Regras Desativadas',
      notFound: 'Nenhuma regra desativada até o momento...',
      loading: 'Carregando...',
      action: {
        enable: 'Ativar'
      }
    },
    dictionary: {
      title: 'Meu Dicionário',
      notFound: 'Nenhuma palavra encontrada...',
      notAdded: 'Nenhuma palavra adicionada até o momento...',
      form: {
        word: {
          placeholder: 'Digite uma palavra...'
        }
      },
      action: {
        add: 'Adicionar',
        delete: 'Excluir'
      }
    },
    suggestion: {
      title: 'Sugestão',
      description: 'Escreva com os detalhes que achar necessário.',
      form: {
        subject: {
          label: 'Qual o assunto?'
        },
        description: {
          label: 'Me diga com detalhes sua sugestão :)'
        }
      },
      action: {
        cancel: 'Cancelar',
        send: 'Enviar'
      }
    },
    conversion: {
      free: {
        one: {
          title: 'Seus créditos estão acabando!',
          description:
            'Mas não se preocupe! Com poucos cliques, você poderá adquirir um dos nossos planos PRO e renovar seu limite. Lembre-se: quando seus créditos acabarem, não será possível gerar mais palavras com IA.'
        },
        two: {
          title: 'Seus créditos acabaram!',
          description:
            'Mas não se preocupe! Com poucos cliques, você poderá adquirir um dos nossos planos PRO e renovar seu limite. Lembre-se: quando seus créditos acabarem, não será possível gerar mais palavras com IA.'
        },
        three: {
          title: 'Seus créditos acabaram :(',
          description:
            'Mas não se preocupe! Com poucos cliques, você poderá adquirir um dos nossos planos PRO e renovar seu limite.'
        },
        action: 'Torne-se PRO',
        actionFreeDays: '7 Dias Grátis'
      },
      paid: {
        one: {
          title: 'Seus créditos estão acabando!',
          description:
            'Mas não se preocupe! Com poucos cliques, você poderá adquirir um dos nossos planos PRO e renovar seu limite. Lembre-se: quando seus créditos acabarem, não será possível gerar mais palavras com IA.'
        },
        two: {
          title: 'Seus créditos estão acabando!',
          description:
            'Mas não se preocupe! Com poucos cliques, você poderá fazer um upgrade do seu plano e renovar seu limite. Lembre-se: quando seus créditos acabarem, não será possível gerar mais palavras com IA.'
        }
      },
      three: {
        title: 'Seus créditos acabaram :(',
        description:
          'Mas não se preocupe! Com poucos cliques, você poderá fazer um upgrade do seu plano e renovar seu limite.'
      },
      action: 'Faça um upgrade'
    },
    summary: {
      title: 'Sumarização',
      notText:
        'Por favor, forneça um texto para que seja possível realizar o processo de sumarização.'
    },
    onboarding: {
      step: {
        one: {
          title: 'Selecione uma palavra para buscar sinônimos',
          description:
            'A funcionalidade de <strong>Buscar Sinônimos</strong> enriquece seus textos, fornecendo sugestões de palavras similares em significado.'
        },
        two: {
          title:
            'Destaque um trecho do texto e receba sugestões de edição e melhoria',
          description:
            'Use os <strong>Ajustes com IA</strong> para simplificar, encurtar, melhorar e mudar o tom e voz do seu texto num piscar de olhos.'
        },
        three: {
          title: 'Corrija desvios automaticamente com ajuda da IA',
          description:
            'Com a <strong>Correção Automática</strong> você recebe sugestões de como corrjir cada desvio do texto com um clique.'
        },
        four: {
          title: 'Digite “/” e veja como a Clarice.ai sugere ideias criativas.',
          description:
            'Deu branco? Use a <strong>Escrita com IA</strong> e supere o bloqueio criativo. Pausa agora só para o café ☕'
        }
      },
      action: {
        close: 'Fechar',
        next: 'Próximo'
      }
    },
    score: {
      performance: {
        title: 'Performance',
        description:
          'A pontuação ao lado representa a qualidade de escrita neste documento. Você pode melhorá-la seguindo as sugestões da Clarice.ai.'
      },
      metrics: {
        title: 'Métricas do Texto',
        options: {
          paragraphs: 'Parágrafos',
          sentences: 'Frases',
          words: 'Palavras',
          characters: 'Caracteres',
          readingTime: 'Tempo de Leitura',
          speakingTime: 'Tempo de Fala'
        }
      },
      readability: {
        title: 'Legibilidade',
        options: {
          wordLength: 'Média de Tamanho das Palavras',
          sentenceLength: 'Média de Palavras por Sentença',
          readabilityScore: {
            title: 'Pontuação',
            description:
              'A pontuação vai de 0 a 100. Pontuações altas indicam que o texto é fácil de ler.'
          }
        }
      },
      legibility: {
        title: 'Legibilidade',
        options: {
          wordLength: 'Média de Tamanho das Palavras',
          sentenceLength: 'Média de Palavras por Sentença',
          complexWord: {
            title: 'Palavras Complexas',
            description:
              'Medimos a profundidade do vocabulário identificando palavras que não estão entre as 5.000  mais usadas em Português.'
          },
          readabilityScore: {
            title: 'Pontuação de Leitura',
            description:
              'A pontuação vai de 0 a 100. Pontuações altas indicam que o texto é fácil de ler.'
          },
          formality: 'Formalidade',
          genre: 'Gênero',
          sentiment: 'Sentimento'
        }
      },
      time: {
        minutes: 'min',
        seconds: 'seg'
      }
    }
  },
  banner: {
    discount: {
      ANUAL40: {
        title: '40% de desconto! 🎉',
        description:
          'Utilize o cupom <b>ANUAL40</b> e ganhe 40% de desconto na assinatura anual. Aproveite!'
      },
      NIVER40: {
        title: '40% de desconto! 🎉',
        description:
          'Utilize o cupom <b>NIVER40</b> e ganhe 40% de desconto na assinatura anual. Aproveite!'
      },
      NIVER60: {
        title: '60% de desconto! 🎉',
        description:
          'Utilize o cupom <b>NIVER60</b> e ganhe 60% de desconto na assinatura. Aproveite!'
      },
      LINGUAPT50OLD: {
        title:
          'Dia 05 de maio celebramos o <b> <br />Dia Mundial da Língua Portuguesa!</b> 🌍🎉',
        description:
          'Utilize o cupom <b>LINGUAPT50</b> e ganhe 50% de desconto na assinatura anual. Aproveite!'
      },
      LINGUAPT50: {
        title: 'Semana Nacional da Língua Portuguesa 📚🎉',
        description:
          'Utilize o cupom <b>LINGUAPT50</b> e ganhe 50% de desconto na assinatura anual. Aproveite!'
      },
      WS50: {
        title: 'Clarice.ai no Web Summit 🥳',
        description:
          'Utilize o cupom <b>WS50</b> e ganhe 50% de desconto na assinatura anual. Aproveite!'
      },
      action: 'Assinar agora'
    },
    googleDocs: {
      title: 'Clarice no Google Docs!',
      description:
        'Experimente nossa extensão para o Google Docs! Instale-a para receber feedbacks da Clarice.ai agora mesmo.',
      action: 'Instalar Grátis'
    }
  },
  shortcut: {
    writer: {
      description: 'Peça para a Clarice escrever...',
      option: '',
      loading: 'A Clarice está escrevendo...'
    },
    complete: {
      description: 'Completar texto',
      option: '',
      loading: 'Completando o texto..'
    },
    createParagraph: {
      description: 'Redigir parágrafo',
      option: 'Escreva um parágrafo sobre',
      loading: 'Redigindo parágrafo...'
    },
    brainstorm: {
      description: 'Gerar ideias',
      option: 'Gerar ideias sobre',
      loading: 'Gerando ideias...'
    },
    blogPost: {
      description: 'Criar postagem de blog',
      option: 'Escreva uma postagem de blog sobre'
    },
    newGenerate: {
      action: 'Gerar novas sugestões',
      loading: 'Gerando novas sugestões...'
    },
    fields: {
      text: {
        label: 'Peça para IA escrever o que quiser...',
        placeholder: 'Peça para IA escrever o que quiser...'
      }
    },
    simplify: {
      description: 'Simplificar',
      loading: 'Simplificando texto...'
    },
    shorten: {
      description: 'Encurtar',
      loading: 'Encurtando texto...'
    },
    better: {
      description: 'Melhorar',
      loading: 'Melhorando texto...'
    },
    tone: {
      description: 'Mudar tom',
      loading: 'Mudando tom...',
      options: {
        back: 'Voltar',
        friendly: 'Tom amigável',
        assertive: 'Tom assertivo',
        constructive: 'Tom construtivo',
        descriptive: 'Tom descritivo',
        happy: 'Tom feliz',
        modest: 'Tom modesto',
        professional: 'Tom profissional',
        neutral: 'Tom neutro',
        optimistic: 'Tom otimista',
        reflective: 'Tom reflexivo'
      }
    }
  }
};

export default LANGUAGE_PT;
