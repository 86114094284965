import React, { useContext, useEffect } from 'react';
import { ThemeContext } from '../../utils/context/ThemeContext';
import { Box, Toolbar, useMediaQuery, useTheme } from '@mui/material';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import { Outlet, useLocation } from 'react-router-dom';
import EditTextPage from './pages/editText';
import {
  GlobalContext,
  GlobalContextProvider
} from '../../utils/context/GlobalContext';
import ModalCreateProjects from './components/Modal/ModalCreateProjects';
import ModalSuggestion from './components/Modal/ModalSuggestion';
import ModalDisabledRules from './components/Modal/ModalDisabledRules';
import ModalDictionary from './components/Modal/ModalDictionary';
import Chatbot from './components/Chatbot';
import PaymentContainer from './pages/payment/old';
import clsx from 'clsx';
import Plans from './pages/plans';
import PaymentCheckout from './pages/payment/checkout';
import IntercomButton from './components/IntercomButton';
import ModalNews from './components/Modal/ModalNews';
import ModalOnboarding from './components/Modal/ModalOnboarding';
import ModalConversion from './components/Modal/ModalConversion';
import ModalAcceptedTerms from './components/Modal/ModalAcceptedTerms';
import { TranslateInterface } from '../../utils/interfaces/TranslateInterface';
import useLanguage from '../../utils/hooks/useLanguage';
import NewText from './pages/newText';

const AppLayout = () => {
  const themeMode = useContext(ThemeContext);
  const theme = useTheme();

  const location: any = useLocation();
  const pathLocation = location.pathname.split('/');
  const ctx = useContext(GlobalContext);

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  const [sidebarExpanded, setSidebarExpanded] = React.useState<any>(!isMobile);

  const { translate }: TranslateInterface = useLanguage();

  const toggleSidebar = () => {
    setSidebarExpanded((prevState: any) => !prevState);
  };

  useEffect(() => {
    if (location && location.pathname) {
      const title = {
        '/home': `Clarice.ai | ${translate('sidebar.route.home')}`,
        '/plans': `Clarice.ai | ${translate('sidebar.route.plans')}`,
        '/brands': `Clarice.ai | ${translate('sidebar.route.brands')}`,
        '/templates': `Clarice.ai | ${translate('sidebar.route.templates')}`,
        '/chat': `Clarice.ai | ${translate('sidebar.route.chat')}`,
        '/documents': `Clarice.ai | ${translate('sidebar.route.documents')}`,
        '/projects': `Clarice.ai | ${translate('sidebar.route.projects')}`,
        '/account/profile': `Clarice.ai | ${translate(
          'sidebar.route.account.profile'
        )}`,
        '/account/edit-account': `Clarice.ai | ${translate(
          'sidebar.route.account.update'
        )}`,
        '/account/credits-billings': `Clarice.ai | ${translate(
          'sidebar.route.account.credits'
        )}`,
        '/account/business': `Clarice.ai | ${translate(
          'sidebar.route.account.business'
        )}`,
        '/backoffice': `Clarice.ai | ${translate('sidebar.route.backoffice')}`,
        '/backoffice/business': `Clarice.ai | ${translate(
          'sidebar.route.updateBusiness'
        )}`
      };

      document.title = title[location.pathname] || 'Clarice.ai';

      if (location.pathname.includes('/edit-text')) {
        document.title = `Clarice.ai | ${translate('sidebar.route.editText')}`;
      }
    }
  }, [location, translate]);

  return (
    <GlobalContextProvider>
      <barepay />

      {pathLocation[1] === 'edit-text' ? (
        <EditTextPage />
      ) : pathLocation[1] === 'premium' ? (
        <PaymentContainer />
      ) : pathLocation[1] === 'plans' ? (
        <Plans />
      ) : pathLocation[1] === 'payment' && pathLocation[2] === 'checkout' ? (
        <PaymentCheckout />
      ) : pathLocation[1] === 'novo-texto' ? (
        <NewText />
      ) : (
        <Box
          sx={{ display: 'flex' }}
          className={clsx(
            themeMode.mode === 'dark' ? 'darkTheme' : '',
            isMobile ? 'isMobile' : '',
            isMobile && themeMode.mode === 'dark' ? 'isMobileDarkTheme' : ''
          )}
        >
          <Navbar
            toggleSidebar={toggleSidebar}
            sidebarExpanded={sidebarExpanded}
          />
          <Box
            component="nav"
            sx={
              isMobile
                ? {
                    width: sidebarExpanded ? '100%' : '0rem',
                    flexShrink: 0,
                    position: isMobile ? 'absolute!important' : 'unset',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    height: '100%',
                    zIndex: '9999'
                  }
                : {
                    width: sidebarExpanded ? '18rem' : '0rem',
                    flexShrink: 0,
                    position: isMobile ? 'absolute!important' : 'unset',
                    padding: '0px'
                  }
            }
            /* onClick={() => (isMobile ? toggleSidebar() : null)} */
          >
            {sidebarExpanded && (
              <Sidebar
                sidebarExpanded={sidebarExpanded}
                temporary={isMobile}
                toggle={setSidebarExpanded}
              />
            )}
          </Box>
          <Box
            component="main"
            sx={
              isMobile
                ? {
                    flexGrow: 1,
                    p: 3,
                    width: sidebarExpanded
                      ? `calc(100% - 0rem)`
                      : `calc(100% - 0rem)`,
                    minHeight: '100vh',
                    backgroundColor:
                      themeMode.mode === 'light'
                        ? `#ffffff`
                        : '#252525!important'
                  }
                : {
                    flexGrow: 1,
                    p: 3,
                    width: sidebarExpanded
                      ? `calc(100% - 18rem)`
                      : `calc(100% - 0rem)`,
                    minHeight: '100vh',
                    backgroundColor:
                      themeMode.mode === 'light'
                        ? `#ffffff`
                        : '#252525!important'
                  }
            }
          >
            <Toolbar />
            <Outlet />
          </Box>
        </Box>
      )}

      <ModalCreateProjects />

      <ModalSuggestion />

      <ModalNews />

      <ModalOnboarding />

      <ModalDictionary />

      <ModalDisabledRules />

      <ModalConversion />

      <ModalAcceptedTerms />

      {/*{location &&*/}
      {/*  !['/chat', '/premium', '/plans'].includes(location?.pathname) &&*/}
      {/*  !location?.pathname.includes('payment/checkout') && <Chatbot />}*/}
    </GlobalContextProvider>
  );
};

export default AppLayout;
