import {
  AccountCircleRounded,
  AppsRounded,
  ArticleRounded,
  BookmarkRounded,
  ContentPasteOffRounded,
  DashboardRounded,
  HomeRounded,
  InfoRounded,
  NewspaperRounded,
  QuestionAnswerRounded
} from '@mui/icons-material';
import ProfilePage from '../../../layouts/App/pages/settings/Profile';
import TemplatesPage from '../../../layouts/App/pages/templates';
import ChatPage from '../../../layouts/App/pages/chat';
import DocumentsPage from '../../../layouts/App/pages/documents';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import SettingsPageLayout from '../../../layouts/App/pages/settings';
import { RouteType } from './interface/config';
import SignIn from '../../../layouts/Auth/pages/SignIn';
import SignUp from '../../../layouts/Auth/pages/SignUp';
import ForgotPassword from '../../../layouts/Auth/pages/ForgotPassword';
import PasswordRecovery from '../../../layouts/Auth/pages/PasswordRecovery';
import CreditsBilling from '../../../layouts/App/pages/settings/CreditsBilling';
import EditProfile from '../../../layouts/App/pages/settings/EditProfile';
import BusinessPage from '../../../layouts/App/pages/settings/Business';
import BackofficePage from '../../../layouts/App/pages/settings/Backoffice/Business/List';
import HomePage from '../../../layouts/App/pages/home/';
import EditTextPage from '../../../layouts/App/pages/editText';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import HelpIcon from '@mui/icons-material/Help';
import DeselectIcon from '@mui/icons-material/Deselect';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import HubIcon from '@mui/icons-material/Hub';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import InsightsIcon from '@mui/icons-material/Insights';
import GTranslateIcon from '@mui/icons-material/GTranslate';
// import PaymentContainer from '../../../layouts/App/pages/payment';
import SummarizeIcon from '@mui/icons-material/Summarize';
import Plans from '../../../layouts/App/pages/plans';
import PaymentCheckout from '../../../layouts/App/pages/payment/checkout';
import ListBrand from '../../../layouts/App/pages/brands/List';
import CreateBrand from '../../../layouts/App/pages/brands/Create';
import UpdateBrand from '../../../layouts/App/pages/brands/Update';
import ListProjects from '../../../layouts/App/pages/projects/List';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';

import BackofficeUpdateBusiness from '../../../layouts/App/pages/settings/Backoffice/Business/Update';
import ToolTranslatePage from '../../../layouts/App/pages/tools/translate';
import ToolAiDetectPage from '../../../layouts/App/pages/tools/aiDetect';
import AgentsPage from '../../../layouts/App/pages/agents';
import NewText from '../../../layouts/App/pages/newText';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import SupportPage from '../../../layouts/App/pages/support';

const routes: RouteType[] = [
  // {
  //   sidebar: 0,
  //   index: true,
  //   element: <SignIn />,
  //   state: 'sign-in',
  //   protected: false
  // },
  {
    index: true,
    element: <HomePage />,
    state: 'home',
    protected: true
  },
  // {
  //   path: '/premium',
  //   index: true,
  //   element: <PaymentContainer />,
  //   state: 'payment',
  //   protected: true
  // },
  {
    path: '/plans',
    index: true,
    element: <Plans />,
    state: 'payment',
    protected: true
  },
  {
    path: '/payment/checkout/*',
    index: true,
    element: <PaymentCheckout />,
    state: 'payment-checkout',
    protected: true
  },
  {
    path: '/novo-texto/*',
    index: true,
    element: <NewText />,
    state: 'new-text',
    protected: true
  },
  {
    path: '/sign-in/*',
    element: <SignIn />,
    state: 'sign-in',
    protected: false,
    child: [
      {
        path: ':code',
        element: <SignIn />,
        state: 'sign-in',
        protected: false,
        sidebar: 0
      }
    ]
  },
  {
    sidebar: 0,
    path: '/sign-up',
    element: <SignUp />,
    state: 'sign-up',
    protected: false
  },
  {
    sidebar: 0,
    path: '/forgot-password',
    element: <ForgotPassword />,
    state: 'forgot-password',
    protected: false
  },
  {
    sidebar: 0,
    path: '/password-recovery/*',
    element: <PasswordRecovery />,
    state: 'password-recovery',
    protected: false,
    child: [
      {
        sidebar: 0,
        path: ':code',
        element: <PasswordRecovery />,
        state: 'password-recovery',
        protected: false
      }
    ]
  },
  {
    sidebar: 1,
    path: '/home',
    element: <HomePage />,
    state: 'home',
    sidebarProps: {
      displayText: 'sidebar.route.home',
      icon: <HomeRounded />
    },
    protected: true
  },
  {
    sidebar: 1,
    hidden: true,
    path: '/agents',
    element: <AgentsPage />,
    state: 'agents',
    sidebarHidden: true,
    sidebarProps: {
      displayText: 'sidebar.route.agents',
      icon: <SupportAgentIcon />
    },
    protected: true
  },
  {
    sidebar: 1,
    hidden: true,
    path: '/agents/:type',
    element: <AgentsPage />,
    state: 'agents',
    sidebarHidden: true,
    sidebarProps: {
      displayText: 'sidebar.route.agents',
      icon: <SupportAgentIcon />
    },
    protected: true
  },
  {
    sidebar: 1,
    onClick: true,
    state: 'agentCopyWriter',
    sidebarProps: {
      displayText: 'sidebar.route.agentCopyWriter',
      icon: <SupportAgentIcon />
    },
    protected: true
  },
  {
    sidebar: 1,
    onClick: true,
    state: 'agentPhotographer',
    sidebarProps: {
      displayText: 'sidebar.route.agentPhotographer',
      icon: <SupportAgentIcon />
    },
    protected: true
  },
  {
    sidebar: 1,
    onClick: true,
    state: 'agentIllustrator',
    sidebarProps: {
      displayText: 'sidebar.route.agentIllustrator',
      icon: <SupportAgentIcon />
    },
    protected: true
  },
  {
    sidebar: 1,
    onClick: true,
    state: 'agentMessages',
    sidebarProps: {
      displayText: 'sidebar.route.agentMessages',
      icon: <SupportAgentIcon />
    },
    protected: true
  },
  {
    sidebar: 1,
    onClick: true,
    state: 'agentService',
    sidebarProps: {
      displayText: 'sidebar.route.agentService',
      icon: <SupportAgentIcon />
    },
    protected: true
  },
  {
    sidebar: 1,
    onClick: true,
    state: 'agentRedactor',
    sidebarProps: {
      displayText: 'sidebar.route.agentRedactor',
      icon: <SupportAgentIcon />
    },
    protected: true
  },
  {
    sidebar: 1,
    onClick: true,
    state: 'agentAcademic',
    sidebarProps: {
      displayText: 'sidebar.route.agentAcademic',
      icon: <SupportAgentIcon />
    },
    protected: true
  },
  {
    sidebar: 1,
    path: '/templates',
    element: <TemplatesPage />,
    state: 'templates',
    sidebarHidden: true,
    sidebarProps: {
      displayText: 'sidebar.route.templates',
      icon: <DashboardRounded />
    },
    protected: true
    // child: [
    //   {
    //     path: ':type',
    //     element: <TemplatesPage />,
    //     state: 'templates',
    //     protected: false
    //   }
    // ]
  },
  {
    sidebar: 1,
    hidden: true,
    path: '/templates/:type',
    element: <TemplatesPage />,
    state: 'templates',
    sidebarHidden: true,
    sidebarProps: {
      displayText: 'sidebar.route.templates',
      icon: <AppsRounded />
    },
    protected: true
  },
  {
    sidebar: 1,
    path: '/chat',
    element: <ChatPage />,
    state: 'chat',
    sidebarProps: {
      displayText: 'sidebar.route.chat',
      icon: <QuestionAnswerRounded />
    },
    protected: true
  },
  {
    sidebar: 1,
    path: '/documents',
    element: <DocumentsPage />,
    state: 'documents',
    sidebarProps: {
      displayText: 'sidebar.route.documents',
      icon: <ArticleRounded />
    },
    protected: true
  },
  {
    sidebar: 1,
    path: '/edit-text/:id',
    element: <EditTextPage />,
    state: 'editText',
    sidebarProps: {
      displayText: 'sidebar.route.editText'
    },
    protected: true,
    hidden: true
  },
  {
    sidebar: 1,
    path: '/projects',
    element: <ListProjects />,
    state: 'projects',
    hidden: true,
    protected: true
  },
  {
    sidebar: 2,
    path: '/brands',
    element: <ListBrand />,
    state: 'brands',
    sidebarProps: {
      displayText: 'sidebar.route.brands',
      icon: <HubIcon />
    },
    hidden: true,
    protected: true
  },
  {
    sidebar: 1,
    path: '/brands/create',
    element: <CreateBrand />,
    state: 'brands-create',
    sidebarProps: {
      displayText: 'sidebar.route.createBrand'
    },
    protected: true,
    hidden: true
  },
  {
    sidebar: 1,
    path: '/brands/update/:id',
    element: <UpdateBrand />,
    state: 'brands-update',
    sidebarProps: {
      displayText: 'sidebar.route.updateBrand'
    },
    protected: true,
    hidden: true
  },
  {
    sidebar: 1,
    path: '/backoffice/business/:id',
    element: <BackofficeUpdateBusiness />,
    state: 'backoffice-business-update',
    sidebarProps: {
      displayText: 'sidebar.route.updateBusiness'
    },
    protected: true,
    hidden: true
  },
  {
    sidebar: 1,
    path: '/backoffice',
    element: <BackofficePage />,
    state: 'backoffice',
    sidebarProps: {
      displayText: 'sidebar.route.backoffice'
    },
    protected: true,
    hidden: true
  },
  {
    sidebar: 1,
    onClick: true,
    state: 'review',
    sidebarProps: {
      displayText: 'sidebar.route.review',
      icon: <AutoAwesomeIcon />
    },
    protected: true
  },
  {
    sidebar: 1,
    onClick: true,
    state: 'rewriter',
    sidebarProps: {
      displayText: 'sidebar.route.rewriter',
      icon: <AutoFixHighIcon />
    },
    protected: true
  },
  {
    sidebar: 1,
    onClick: true,
    state: 'summarizer',
    sidebarProps: {
      displayText: 'sidebar.route.summarizer',
      icon: <SummarizeIcon />
    },
    protected: true
  },
  {
    sidebar: 1,
    hidden: false,
    path: '/tools/translate',
    element: <ToolTranslatePage />,
    state: 'translate',
    sidebarHidden: true,
    sidebarProps: {
      displayText: 'sidebar.route.translate',
      icon: <GTranslateIcon />
    },
    protected: true
  },
  {
    sidebar: 1,
    hidden: false,
    path: '/tools/ai-detect',
    element: <ToolAiDetectPage />,
    state: 'aiDetect',
    sidebarHidden: true,
    sidebarProps: {
      displayText: 'sidebar.route.aiDetect',
      icon: <CrisisAlertIcon />
    },
    protected: true
  },
  {
    sidebar: 2,
    onClick: true,
    state: 'disabledRules',
    sidebarProps: {
      displayText: 'sidebar.route.disabledRules',
      icon: <ContentPasteOffRounded />
    },
    protected: true
  },
  {
    sidebar: 2,
    onClick: true,
    state: 'dictionary',
    sidebarProps: {
      displayText: 'sidebar.route.dictionary',
      icon: <BookmarkRounded />
    },
    protected: true
  },
  {
    hidden: true,
    sidebar: 1,
    path: '/account',
    element: <SettingsPageLayout />,
    state: 'account',
    sidebarProps: {
      displayText: 'Conta',
      icon: <AccountCircleRounded />
    },
    protected: true,
    child: [
      {
        sidebar: 1,
        path: '/account/profile',
        element: <ProfilePage />,
        state: 'account.profile',
        sidebarProps: {
          displayText: 'sidebar.route.account.profile'
          /* icon: <PersonRounded />, */
        }
      },
      {
        sidebar: 1,
        path: '/account/edit-account',
        element: <EditProfile />,
        state: 'account.edit-account',
        sidebarProps: {
          displayText: 'sidebar.route.account.update'
          /* icon: <DarkModeRounded />, */
        }
      },
      {
        sidebar: 1,
        path: '/account/credits-billings',
        element: <CreditsBilling />,
        state: 'account.credits-billings',
        sidebarProps: {
          displayText: 'sidebar.route.account.credits'
        }
      },
      {
        sidebar: 2,
        path: '/account/business',
        element: <BusinessPage />,
        state: 'account.business',
        sidebarProps: {
          displayText: 'sidebar.route.account.business'
          /* icon: <PersonRounded />, */
        }
      }
    ]
  },
  // {
  //   sidebar: 2,
  //   onClick: true,
  //   state: 'news',
  //   sidebarProps: {
  //     displayText: 'Novidades',
  //     icon: <NewspaperRounded />
  //   },
  //   protected: true
  // },
  // {
  //   sidebar: 2,
  //   onClick: true,
  //   state: 'terms',
  //   sidebarProps: {
  //     displayText: 'Termos de Uso',
  //     icon: <HelpIcon />
  //   },
  //   protected: true
  // },
  // {
  //   sidebar: 2,
  //   onClick: true,
  //   state: 'privacyPolicy',
  //   sidebarProps: {
  //     displayText: 'Política de Privacidade',
  //     icon: <PrivacyTipIcon />
  //   },
  //   protected: true
  // },
  // {
  //   sidebar: 2,
  //   path: '/help',
  //   element: <SettingsPageLayout />,
  //   state: 'help',
  //   sidebarProps: {
  //     displayText: 'Ajuda',
  //     icon: <InfoRounded />
  //   },
  //   protected: true
  // },
  {
    sidebar: 2,
    onClick: true,
    state: 'help',
    sidebarProps: {
      displayText: 'sidebar.route.help',
      icon: <InfoRounded />
    },
    protected: true
  },
  {
    sidebar: 2,
    path: '/support',
    element: <SupportPage />,
    state: 'support',
    sidebarHidden: true,
    sidebarProps: {
      displayText: 'sidebar.route.support',
      icon: <SupportAgentIcon />
    },
    protected: true
  }
];

export default routes;
